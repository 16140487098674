import { setConfigReportForPurchase, setListOfPivotConfig, setListOfProducts, setListOfUsers, setOrderStatus, setOrderSupplier, setOrderWarehouse, setPivotConfig, setPurchaseReport, setReportExcelData } from "../slice/reportSlice";
import { apiSlice } from "./apiSlice";

export const reportApiSliceV2 = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createReport: builder.mutation({
            query: ({ params, data }) => {
                return ({
                    url: `${params}`,
                    method: "POST",
                    body: data,
                })
            },
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(res => dispatch(setPurchaseReport(res.data))).catch(err => { });
            },
        }),
        getReportById: builder.query({
            query: (params) => params,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result =>
                        dispatch(setPurchaseReport(result.data))
                    )
                    .catch(error => { });
            },
        }),
        getReportConfigOfPurchaseOrder: builder.query({
            query: (params) => `/api_report/report_config/${params}`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result =>
                        dispatch(setConfigReportForPurchase(result.data))
                    )
                    .catch(error => { });
            },
        }),
        getListOfOrderStatus: builder.query({
            query: (params) => params,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setOrderStatus(result.data))).catch(error => { });
            },
        }),
        getListOfSupplier: builder.query({
            query: () => `/api_supplier/supplier/?mode=selection`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setOrderSupplier(result.data))).catch(error => { });
            },
        }),
        getListOfWarehouse: builder.query({
            query: () => `/api_supplier/warehouse/?mode=selection`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setOrderWarehouse(result.data))).catch(error => { });
            },
        }),
        getListOfProducts: builder.query({
            query: () => `/api_product/product/?mode=selection`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setListOfProducts(result.data))).catch(error => { });
            },
        }),


        getListOfPivotConfig: builder.query({
            query: (params) => `/api_report/pivot_config/?module=${params}`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setListOfPivotConfig(result.data))).catch(error => { });
            },
        }),
        getPivotConfig: builder.query({
            query: (id) => `/api_report/pivot_config/${id}/`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setPivotConfig(result.data))).catch(error => { });
            },
        }),
        deleteConfigReport: builder.mutation({
            query: (id) => ({
                url: `/api_report/pivot_config/${id}/`,
                method: "DELETE",
            }),
        }),
        shareReport: builder.mutation({
            query: ({ params, data }) => {
                return ({
                    url: `${params}`,
                    method: "POST",
                    body: data,
                })
            },
        }),
        updateAccessOfReport: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_report/pivot_config/${id}/scope/`,
                method: "PUT",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setReportExcelData(result.data))).catch(error => { });
            },
        }),
        getUsersV2: builder.query({
            query: (params) => params ? `/api_user/v2/user/selection/?${params}` : `/api_user/v2/user/selection/`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled
                    .then(result => dispatch(setListOfUsers(result.data))).catch(error => { });
            },
        }),
        createAutomation: builder.mutation({
            query: (data) => {
                return ({
                    url: `/api_report/schedule/`,
                    method: "POST",
                    body: data,
                })
            },
        }),
    }),
});

export const { useCreateReportMutation,
    useGetReportByIdQuery,
    useGetReportConfigOfPurchaseOrderQuery,
    useGetListOfPivotConfigQuery,
    useDeleteConfigReportMutation,
    useGetListOfOrderStatusQuery,
    useGetListOfSupplierQuery,
    useGetListOfWarehouseQuery,
    useGetListOfProductsQuery,
    useShareReportMutation,
    useUpdateAccessOfReportMutation,
    useGetUsersV2Query,
    useCreateAutomationMutation,
    useGetPivotConfigQuery,
} = reportApiSliceV2