import {
  addActivityLogToTransferOrder,
  addTransferOrderFiles,
  addTransferOrderNote,
  editMultiTransferOrderLines,
  editTransferOrder,
  getPoLinePurchaseOrders,
  getPurchaseOrderLineProducts,
  getToLineTransferOrders,
  getTransferOrderLineProducts,
  getTransferOrderLineStatus,
  setPurchaseAllocations,
  setTransferAllocations,
  setTransferOrder,
  setTransferOrderBills,
  setTransferOrderFiles,
  setTransferOrderLines,
  setTransferOrderNotes,
  setTransferOrders,
  setTransferOrdersSelection,
  setTransferOrderStatus,
  updateAllocationsAfterAddToShipment,
  updateMultiTFOrderLineReceiptedQty,
  updatePurchaseAllocatedQty,
  updatePurchaseOrderAllocationStatus,
  updateTransferAllocatedQty,
  updateTransferOrderAllocationStatus,
  updateTransferOrderLines,
  updateTransferOrderLineStatus,
} from "../slice/orderSlice";
import { setAllTransferOrders, setOrderlineMapById } from "../slice/TransferOrderSliceV2";
import { apiSlice } from "./apiSlice";

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addOrderLinesToConsignment: builder.mutation({
      query: ({ consignment_id, ...data }) => ({
        url: `/api_shipment/consignment/${consignment_id}/add_to_shipment/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateAllocationsAfterAddToShipment(data)))
          .catch(error => { });
      },
    }),
    addTfOrdLinesToPO: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_po/purchase_order/${id}/bulk_update/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateTransferOrderLines({ ...result.data, ...data })))
          .catch(error => { });
      },
    }),
    autoStockAllocation: builder.mutation({
      query: () => ({ url: "/api_warehouse/auto_allocation/", method: "POST", body: {} }),
    }),
    createTransferOrderNote: builder.mutation({
      query: data => ({
        url: "/api_chat/note/",
        method: "POST",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(addTransferOrderNote(result.data)))
          .catch(error => { });
      },
    }),
    createPlnOrdeFromProjectedStock: builder.mutation({
      query: data => ({ url: "/api_order/planned_order/", method: "POST", body: data }),
    }),
    createShipmentFromPurchaseAllocation: builder.mutation({
      query: data => ({
        url: "/api_shipment/create_purchase_consignment/",
        method: "POST",
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(orderApiSlice.util.invalidateTags(["allocatedPurchaseList"]));
        } catch (error) { }
      },
    }),
    createShipmentFromTransferAllocation: builder.mutation({
      query: data => ({
        url: "/api_shipment/create_transfer_consignment/",
        method: "POST",
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(orderApiSlice.util.invalidateTags(["allocatedTransferList"]));
        } catch (error) { }
      },
    }),
    createTransferOrderBill: builder.mutation({
      query: data => ({
        url: `/api_invoice/bill/`,
        method: "POST",
        body: data,
      }),
    }),
    createTransferOrderFiles: builder.mutation({
      query: data => ({
        url: `/api_docdigit/document/create_tf_ord_files/`,
        method: "POST",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(addTransferOrderFiles(result.data)))
          .catch(error => { });
      },
    }),
    downloadTransferOrderPdf: builder.query({
      query: id => ({
        url: `/api_order/transfer_order/${id}/download_pdf/`,
        method: "GET",
        headers: { "Content-Type": "application/pdf" },
        responseHandler: response => response.blob(),
      }),
    }),
    editMultiTFOrderLineReceiptedQty: builder.mutation({
      query: ({ id, ...data }) => ({
        url: "api_order/transfer_order_line/update_receipted_qty/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultiTFOrderLineReceiptedQty(result.data.data)))
          .catch(error => { });
      },
    }),
    getBillsForTransferOrder: builder.query({
      query: params => `/api_invoice/bill/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setTransferOrderBills(result.data)))
          .catch(error => { });
      },
    }),
    getFilesForTransferOrder: builder.query({
      query: params => `/api_docdigit/document/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setTransferOrderFiles(result.data)))
          .catch(error => { });
      },
    }),
    getPoLinePurchaseOrders: builder.query({
      query: (params = null) =>
        params ? `/api_po/get_purchase_orders?${params}` : "/api_po/get_purchase_orders/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPoLinePurchaseOrders(result.data)))
          .catch(error => { });
      },
    }),
    getPurchaseAllocations: builder.query({
      query: params => (params ? `/api_po/allocation/?${params}` : "/api_po/allocation"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => {
            dispatch(setPurchaseAllocations(result.data))
            dispatch(setOrderlineMapById(result.data.results))
          })
          .catch(error => { });
      },
      providesTags: _ => ["allocatedPurchaseList"],
    }),
    getPurchaseOrderLineProducts: builder.query({
      query: () => "/api_po/purchase_order_line_products/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderLineProducts(result.data.data)))
          .catch(error => { });
      },
    }),
    getTFOrderActivityLogs: builder.query({
      query: ({ id }) => `/api_order/tf_order_activity_log/${id}/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(addActivityLogToTransferOrder(result.data)))
          .catch(error => { });
      },
    }),
    getToLineTransferOrders: builder.query({
      query: () => "/api_order/get_transfer_orders/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getToLineTransferOrders(result.data.data)))
          .catch(error => { });
      },
    }),
    getTransferAllocations: builder.query({
      query: params => (params ? `/api_order/allocation/?${params}` : "/api_order/allocation"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => {
            dispatch(setTransferAllocations(result.data))
            dispatch(setOrderlineMapById(result.data.results))
          }).catch(error => { });
      },
      providesTags: _ => ["allocatedTransferList"],
    }),
    getTransferOrder: builder.query({
      query: id => `/api_order/transfer_order/${id}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setTransferOrder(result.data))).catch(error => { });
      },
    }),
    getTransferOrderNotes: builder.query({
      query: params => (params ? `/api_chat/note/?${params}` : "/api_chat/note/"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setTransferOrderNotes(result.data)))
          .catch(error => { });
      },
    }),
    getTransferOrderLineProducts: builder.query({
      query: () => "/api_order/transfer_order_line_products/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getTransferOrderLineProducts(result.data.data)))
          .catch(error => { });
      },
    }),
    getTransferOrderLines: builder.query({
      query: params =>
        params ? `/api_order/transfer_order_line/?${params}` : "/api_order/transfer_order_line/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setTransferOrderLines(result.data)))
          .catch(error => { });
      },
    }),
    getTransferOrderLineStatus: builder.query({
      query: () => "/api_order/transfer_order_line_status/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getTransferOrderLineStatus(result.data)))
          .catch(error => { });
      },
    }),
    getTransferOrders: builder.query({
      query: params =>
        params ? `/api_order/transfer_order/?${params}` : "/api_order/transfer_order/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => {
          dispatch(setTransferOrders(result.data))
          dispatch(setAllTransferOrders(result.data.results))
        }).catch(error => { });
      },
      providesTags: _ => ["transferOrderList"],
    }),
    getTransferOrdersSelection: builder.query({
      query: params =>
        params ? `/api_order/transfer_order/?${params}` : "/api_order/transfer_order/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setTransferOrdersSelection(result.data)))
          .catch(error => { });
      },
      providesTags: _ => ["transferOrderList"],
    }),
    getTransferOrderStatus: builder.query({
      query: () => "/api_order/transfer_order_status/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setTransferOrderStatus(result.data)))
          .catch(error => { });
      },
    }),
    importTransferOrder: builder.mutation({
      query: () => ({ url: "/api_order/import_transfer_order", method: "POST", body: {} }),
    }),
    mutatePurchaseAllocatedQty: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_po/po_line/${id}/update_allocated_qty/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updatePurchaseAllocatedQty(result.data)))
          .catch(error => { });
      },
    }),
    mutateTransferAllocatedQty: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_order/transfer_order_line/${id}/update_allocated_qty/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateTransferAllocatedQty(result.data)))
          .catch(error => { });
      },
    }),
    mutateTransferOrderLineStatus: builder.mutation({
      query: data => ({
        url: `/api_order/transfer_order_line/update_status/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => dispatch(updateTransferOrderLineStatus(data))).catch(error => { });
      },
    }),
    splitPOLines: builder.mutation({
      query: data => ({
        url: "/api_po/v2/po_line/bulk_split/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(orderApiSlice.util.invalidateTags(["allocatedPurchaseList"]));
        } catch (error) { }
      },
    }),
    splitTfOrdLines: builder.mutation({
      query: data => ({
        url: "/api_order/v2/tf_ord_line/bulk_split/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(orderApiSlice.util.invalidateTags(["allocatedTransferList"]));
        } catch (error) { }
      },
    }),
    updateDispatchPurchaseAllocations: builder.mutation({
      query: data => ({
        url: "/api_po/allocation/bulk_update_dispatched_qty/",
        method: "PATCH",
        body: data,
      }),
    }),
    updateDispatchTfAllocations: builder.mutation({
      query: data => ({
        url: "/api_order/allocation/bulk_update_dispatched_qty/",
        method: "PATCH",
        body: data,
      }),
    }),
    updatePurchaseOrderAllocationStatus: builder.mutation({
      query: data => ({
        url: "/api_po/allocation/update_purchase_order_allocation_status/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => {
            dispatch(updatePurchaseOrderAllocationStatus(result.data.data))
            dispatch(orderApiSlice.util.invalidateTags(['allocatedPurchaseList']))
          })
          .catch(error => { });
      },
    }),
    updateTransferOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_order/transfer_order/${id}/update_transfer_order/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(editTransferOrder(result.data.data)))
          .catch(error => { });
      },
    }),
    updateTransferOrderAllocationStatus: builder.mutation({
      query: data => ({
        url: "/api_order/allocation/update_transfer_order_allocation_status/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => {
            dispatch(updateTransferOrderAllocationStatus(result.data.data))
            dispatch(orderApiSlice.util.invalidateTags(["allocatedTransferList"]))
          })
          .catch(error => { });
      },
    }),
    updateTransferOrderLinesETA: builder.mutation({
      query: data => ({
        url: "/api_order/transfer_order_line/bulk_update_eta/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(editMultiTransferOrderLines(result.data)))
          .catch(error => { });
      },
    }),
    updateTransferOrderName: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_order/transfer_order/${id}/update_transfer_order_name/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(editTransferOrder(result.data))).catch(error => { });
      },
    }),
  }),
});

export const {
  useAddOrderLinesToConsignmentMutation,
  useAddTfOrdLinesToPOMutation,
  useAutoStockAllocationMutation,
  useCreatePlnOrdeFromProjectedStockMutation,
  useCreateShipmentFromPurchaseAllocationMutation,
  useCreateShipmentFromTransferAllocationMutation,
  useCreateTransferOrderBillMutation,
  useCreateTransferOrderFilesMutation,
  useCreateTransferOrderNoteMutation,
  useEditMultiTFOrderLineReceiptedQtyMutation,
  useGetBillsForTransferOrderQuery,
  useGetFilesForTransferOrderQuery,
  useGetPoLinePurchaseOrdersQuery,
  useGetPurchaseAllocationsQuery,
  useGetPurchaseOrderLineProductsQuery,
  useGetToLineTransferOrdersQuery,
  useGetTransferAllocationsQuery,
  useGetTransferOrderLineProductsQuery,
  useGetTransferOrderLinesQuery,
  useGetTransferOrderLineStatusQuery,
  useGetTransferOrderNotesQuery,
  useGetTransferOrderQuery,
  useGetTransferOrdersQuery,
  useGetTransferOrdersSelectionQuery,
  useGetTransferOrderStatusQuery,
  useImportTransferOrderMutation,
  useLazyDownloadTransferOrderPdfQuery,
  useLazyGetTFOrderActivityLogsQuery,
  useMutatePurchaseAllocatedQtyMutation,
  useMutateTransferAllocatedQtyMutation,
  useMutateTransferOrderLineStatusMutation,
  useSplitPOLinesMutation,
  useSplitTfOrdLinesMutation,
  useUpdateDispatchPurchaseAllocationsMutation,
  useUpdateDispatchTfAllocationsMutation,
  useUpdatePurchaseOrderAllocationStatusMutation,
  useUpdateTransferOrderAllocationStatusMutation,
  useUpdateTransferOrderLinesETAMutation,
  useUpdateTransferOrderMutation,
  useUpdateTransferOrderNameMutation,
} = orderApiSlice;
