import styled from 'styled-components'

export const Button = ({ btnName, handler, classOfBtn, isDisabledButton = false, titleForDisabledBtn, titleForActive }) => {
    return (
        <ButtonElement className={classOfBtn} onClick={handler} title={isDisabledButton ? titleForDisabledBtn : titleForActive} disabled={isDisabledButton ? true : false}>{btnName}</ButtonElement>
    )
}

const ButtonElement = styled.button`
    color: var(--White, #FFF);
    height: 32px;
    line-height: 16px; 
    padding: 4px 15px;
    border-radius: 4px;
    background-color: var(--BLUE, #2060EA);
    border: 1px solid transparent; 
    &:disabled{
        background-color: var(--MIST, #f7f8f9) !important;
        color: var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25)) !important;
    }
`