import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dateRangeSelection: { startDate: null, endDate: null, reset: false, key: "" },

  notification: null,

  notificationPreferences: [],

  notifications: [],

  queryPagination: "",

  settings: { precision: 4, round: false, displayTime: false },
};

export const miscSlice = createSlice({
  name: "misc",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
    },
    markAllAsRead: state => {
      state.notifications = state.notifications.map(notification => ({
        ...notification,
        is_read: true,
      }));
    },
    resetNotificationPreferences: state => {
      state.notificationPreferences = initialState.notificationPreferences;
    },
    setDateSelectionRange: (state, action) => {
      state.dateRangeSelection = action.payload;
    },
    setDisplayTime: (state, action) => {
      state.settings.displayTime = action.payload;
    },
    resetQueryPagination: (state, action) => {
      state.queryPagination = initialState.queryPagination;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setNotificationPreferences: (state, action) => {
      state.notificationPreferences = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setQueryPagination: (state, action) => {
      state.queryPagination = action.payload;
    },
    updateNotification: (state, action) => {
      const index = state.notifications.findIndex(item => item.id === action.payload.id);
      state.notifications[index] = { ...state.notifications[index], ...action.payload };
    },
    updateNotificationPreferences: (state, action) => {
      const data = action.payload;
      const index = state.notificationPreferences[data.source][data.event].findIndex(
        item => item.id === action.payload.id,
      );
      state.notificationPreferences[data.source][data.event][index] = data;
    },
    updateSettings: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
  },
});

export const {
  addNotification,
  markAllAsRead,
  resetNotificationPreferences,
  resetQueryPagination,
  setDateSelectionRange,
  setDisplayTime,
  setNotification,
  setNotificationPreferences,
  setNotifications,
  setQueryPagination,
  updateNotification,
  updateNotificationPreferences,
  updateSettings,
} = miscSlice.actions;

export default miscSlice.reducer;
