import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";
import { lazy, Suspense } from "react";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { Navigate, Route, Routes } from "react-router-dom";

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import("../app/account/Page"));
  const BillPage = lazy(() => import("../app/bill/Page"));
  const ChatPage = lazy(() => import("../app/chat/Page"));
  const CompliancePage = lazy(() => import("../app/compliance/Page"));
  const FileManagerPage = lazy(() => import("../app/fileManager/Page"));
  const IntegrationPage = lazy(() => import("../app/integration/Page"));
  const InventoryPage = lazy(() => import("../app/inventory/Page"));
  const InvoicePage = lazy(() => import("../app/invoice/Page"));
  const MasterPage = lazy(() => import("../app/master/Page"));
  const OrderPage = lazy(() => import("../app/order/Page"));
  const PlannerPage = lazy(() => import("../app/planner/Page"));
  const PurchaseOrdersPage = lazy(() => import("../app/purchase/Page"));
  const ReceiptPage = lazy(() => import("../app/receipt/Page"));
  const ShipmentPage = lazy(() => import("../app/shipment/Page"));
  const ShipmentPageV2 = lazy(() => import("../app/shipment/PageV2"));
  const SupplierPage = lazy(() => import("../app/supplier/Page"));
  const UserPage = lazy(() => import("../app/user/Page"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />

        {/* Lazy Apps */}
        <Route
          path="chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        <Route
          path="account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path="bill/*"
          element={
            <SuspensedView>
              <BillPage />
            </SuspensedView>
          }
        />

        <Route
          path="compliance/*"
          element={
            <SuspensedView>
              <CompliancePage />
            </SuspensedView>
          }
        />

        <Route
          path="integration/*"
          element={
            <SuspensedView>
              <IntegrationPage />
            </SuspensedView>
          }
        />

        <Route
          path="invoices/*"
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />

        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />

        <Route
          path="inventory/*"
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />

        <Route
          path="purchase_orders/*"
          element={
            <SuspensedView>
              <PurchaseOrdersPage />
            </SuspensedView>
          }
        />

        <Route
          path="receipts/*"
          element={
            <SuspensedView>
              <ReceiptPage />
            </SuspensedView>
          }
        />

        <Route
          path="shipments/*"
          element={
            <SuspensedView>
              <ShipmentPage />
            </SuspensedView>
          }
        />

        <Route
          path="shipment/*"
          element={
            <SuspensedView>
              <ShipmentPageV2 />
            </SuspensedView>
          }
        />

        <Route
          path="supplier-metrics/*"
          element={
            <SuspensedView>
              <SupplierPage />
            </SuspensedView>
          }
        />

        <Route
          path="file-manager/*"
          element={
            <SuspensedView>
              <FileManagerPage />
            </SuspensedView>
          }
        />

        <Route
          path="users/*"
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path="planner/*"
          element={
            <SuspensedView>
              <PlannerPage />
            </SuspensedView>
          }
        />

        <Route
          path="master/*"
          element={
            <SuspensedView>
              <MasterPage />
            </SuspensedView>
          }
        />

        <Route path="*" element={<Navigate to="dashboard" />} />
      </Route>
    </Routes>
  );
};

export const SuspensedView = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");

  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
