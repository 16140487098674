import { ReactComponent as EditIcon } from '../../../assets/svgIcon/shipment/Edit.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/svgIcon/shipment/DeleteOutlined.svg'
import { format } from 'date-fns'

export const NotesList = ({ listOfItem, handleCheckboxChange, toggleEditBox, openEditBox, deleteNote, classForCheckedNotes }) => {
    return (
        <ul className={classForCheckedNotes ? `noteList ${classForCheckedNotes}` : "noteList"}>
            {listOfItem.map((item) => (
                <li key={item.id}>
                    <input
                        type="checkbox"
                        className="checkBox"
                        checked={item.is_done}
                        onChange={(e) => handleCheckboxChange(e, item.id)}
                    />
                    {
                        <div className="content">
                            <span
                                onClick={() => toggleEditBox(item)}
                                className={classForCheckedNotes ? 'checkedItem' : ''}
                            >
                                {item.content}
                                <span className='updatedBy'>{item.updated_by ? 'Updated by' : 'Created by'} {item.updated_by ? item.updated_by.full_name : item.created_by?.full_name} </span>
                                <span className='updatedBy'>{format(item.last_modified, 'dd/MM/yyyy, hh:mm a')}</span>
                            </span>

                            <div className="btnGroup">
                                <span className="iconWrapper" title="Edit" onClick={() => openEditBox(item)}><EditIcon /> </span>
                                <span className="iconWrapper" title="Delete" onClick={() => deleteNote(item.id)}><DeleteIcon /> </span>
                            </div>
                        </div>
                    }
                </li>
            ))}
        </ul>
    )
}
