import { MainMenu } from './Menu/MainMenu/MainMenu'
import { Shipment } from './page/Shipment/Shipment'
import { Outlet } from 'react-router-dom';

export function NewLayout() {
  return (
    <div className='d-flex overflow-hidden'>
      <MainMenu />
      <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
        <Shipment />
      </div>
    </div>
  )
}

export function NewMasterLayout() {
  return (
    <div className='d-flex overflow-hidden'>
      <MainMenu />
      <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
        <Outlet />
      </div>
    </div>
  )
}