import { TopBarOfOrderDetails } from './TopBarOfOrderDetails'
import { DetailsOfOrder } from './DetailsOfOrder'
import { NavigationInDetails } from './NavigationInDetails'
import { PurchaseOrderLines } from './PurchaseOrderLines'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useGetPurchaseOrderDetailQuery } from '../../../../../store/apiSlice/purchaseOrderApiSlice'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import "../../../page/Shipment/Shipment.css"

export const PurchaseOrderDetails = () => {
    const { order_id } = useParams();
    const { refetch } = useGetPurchaseOrderDetailQuery(order_id);
    const { po_line = [], ...orderData } = useSelector(state => state.purchaseOrder.purchaseOrder || {})

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [])

    let orderDetails = {};
    if (orderData.status) {
        orderDetails = {
            supplierName: orderData.supplier.name,
            creator: orderData.creator,
            create_date: orderData.create_date,
            currencyName: orderData.currency.name,
            status: orderData.status,
            wh_opr_type: orderData.wh_opr_type
        }
    }

    return (
        <OrderDetails>
            <TopBarOfOrderDetails headerName={"Purchase Order"} headerId={orderData.name} />
            <DetailsOfOrder order={orderDetails} />
            <NavigationInDetails />
            <PurchaseOrderLines po_line={po_line} orderType="purchase" />
        </OrderDetails>
    )
};


export const OrderDetails = styled.div`
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;

  .dataNotPresent {
    color: #99a1b7;
  }
    >.detailsOfOrder{
        margin: 16px 24px 0;
        border-radius: 8px;
        height: 120px;
        background: var(--SKY, #CCE5FF);
        display: flex;
        align-items: center;

        >.detailItem{
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 0 16px;
            >.valueOfItem{
                color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px; 
                select{
                    padding: 2px 4px;
                    background-color: var(--SKY, #CCE5FF);
                    outline: none;
                    border-radius: 4px;
                }

            }
            >.headerOfItem{
                color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                
            }
            >.statusDetails{
                display: flex;
                gap: 8px;
                color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; 
                >.link{
                    color: var(--BLUE, #2060EA);
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 22px; 
                }
            }
        }
        >.border{
            width: 1px;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.06);
        }

    }
    
    >.navigationOfDetails{
        display: flex;
        justify-content: space-between;
        margin: 16px 24px 0;

        .navItem{
            cursor: pointer;
            padding: 12px 0px;
            color: var(--character-primary-85, rgba(0, 0, 0, 0.85)); 
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            
        }
        .navItem.activeItem{
            color: var(--BLUE, #2060EA);
            border-bottom: 2px solid  var(--DARK-BLUE, #1249BF);

        }
        >.leftSide{
            display: flex;
            >.left{
                display: flex;
                gap: 24px;
            }
            >.leftMargin{
                margin-left: 32px;
            }
        }
        >.btnGroup {
            display: flex;
            gap: 8px;
            align-items: center;
        }
        .iconWrapper {
            font-weight: 400;
            line-height: 1.5;
            padding: 0px 4px;
            border-radius: 4px;
            height: max-content;
            cursor: pointer;
            padding-bottom: 2px;
            &:hover {
            background-color: #2060ea;
            color: white;
            svg path {
                fill: white;
            }
            }
        }
    }
`
