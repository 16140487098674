export const dataForSort = {
    eta: ['Oldest to Newest', 'Newest to Oldest'],
    status: ['Ascending', 'Descending']
}

export const dateFields = ['eta', 'order date', 'create date', 'estimated_date'];

export const nameOfFields = {
    eta: "eta",
    "order date": "date_of_creation",
    'warehouse': 'warehouse',
    'to warehouse': 'to_warehouse',
    'from warehouse': 'from_warehouse',
    'status': 'status',
    'shipment name': 'reference',
    'eta date': 'estimated_date',
    'create date': 'create_date',
    'supplier': 'supplier',
    'created by': 'creator',
    'erp supplier': 'erp_supplier',
    'consolidation status': 'consolidation_status',
} 