import styled from "styled-components"
import { ReactComponent as LeftArrow } from '../../../assets/svgIcon/detailsPage/left-arrow.svg'
import { ButtonForTopBar } from "./ButtonForTopBar"
import { useNavigate } from "react-router-dom"

export const TopBarOfOrderDetails = ({ headerName, headerId }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };
    return (
        <TopBar>
            <div className="left">
                <span onClick={handleGoBack}>
                    <LeftArrow />
                </span>
                <h2>{headerName} #{headerId}</h2>
            </div>
            <div className="right">
                <ButtonForTopBar isExistIcon={true} btnName="Edit Details" handleClick={() => ''} />
            </div>
        </TopBar>
    )
}

const TopBar = styled.div`
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--Color-Tokens-Border-Primary, #E4E4E4);
    background-color: var(--CLOUD, #FCFDFE);

    >.left{
        display: flex;
        gap: 8px;
        align-items: center;
        >span{
            cursor: pointer;
        }
        h2{
            margin-bottom: 0;
            color: #0E0E2C;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; 
        }
    }


`