import { Route, Routes } from "react-router-dom"
import { lazy } from "react";
import { NewMasterLayout } from "../app/newLayout/NewLayout";
import { SuspensedView } from "./PrivateRoutes";

export const PrivateRoutes2 = () => {
    const Page = lazy(() => import("../app/newLayout/page/Purchase/Page"));
    const ShipmentPage = lazy(() => import("../app/newLayout/page/Shipment/ShipmentPage"));
    const TransferOrder = lazy(() => import("../app/newLayout/page/Transfer/TransferPage"));
    const Inventory = lazy(() => import("../app/newLayout/page/Inventory/InventoryPage"));
    const ReportsPage = lazy(() => import("../app/newLayout/page/Reports/ReportsPage"));
    const Allocation = lazy(() => import("../app/newLayout/page/Allocation/Allocation"));

    return (
        <Routes>
            <Route element={<NewMasterLayout />}>

                {/* Lazy Apps */}
                <Route
                    path="purchase/*"
                    element={
                        <SuspensedView>
                            <Page />
                        </SuspensedView>
                    }
                />

                <Route
                    path="shipment/international/*"
                    element={
                        <SuspensedView >
                            <ShipmentPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path="orders/transfer/*"
                    element={
                        <SuspensedView>
                            <TransferOrder />
                        </SuspensedView>
                    }
                />


                <Route
                    path="inventory/*"
                    element={
                        <SuspensedView>
                            <Inventory />
                        </SuspensedView>
                    }
                />

                <Route
                    path="orders/allocation/*"
                    element={
                        <SuspensedView>
                            <Allocation />
                        </SuspensedView>
                    }
                />

                <Route
                    path="reports/*"
                    element={
                        <SuspensedView>
                            <ReportsPage />
                        </SuspensedView>
                    }
                />

            </Route>
        </Routes>
    )
}
