import { MainMenu } from '../../Menu/MainMenu/MainMenu'
import { PurchaseOrder } from "./PurchaseOrder";

export function PurchasePage() {
    return (
        <div className='d-flex overflow-hidden'>
            <MainMenu />
            <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
                <PurchaseOrder />
            </div>
        </div>
    )
}
