const UpDown = ({color= "#676879"})=>(
<svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 1261156505">
<path id="Union" d="M2.51343 0.000192909C2.67973 0.000192909 2.83015 0.0682468 2.93843 0.178057L4.8519 2.09395C5.08513 2.32747 5.08513 2.7061 4.8519 2.93963C4.61867 3.17316 4.24052 3.17316 4.00729 2.93963L3.11066 2.04186V11.4021C3.11066 11.7324 2.84327 12.0001 2.51343 12.0001C2.18359 12.0001 1.9162 11.7324 1.9162 11.4021V2.04183L1.01954 2.93963C0.786303 3.17316 0.408158 3.17316 0.174925 2.93963C-0.0583082 2.7061 -0.0583082 2.32747 0.174925 2.09395L2.09111 0.175339C2.10273 0.1637 2.11478 0.15259 2.12723 0.142025C2.23136 0.0535569 2.36617 0.000192909 2.51343 0.000192909Z" fill={color}/>
<path id="Union_2" d="M8.33734 11.9999C8.50364 11.9999 8.65406 11.9319 8.76235 11.8221L10.6758 9.90618C10.909 9.67265 10.909 9.29402 10.6758 9.0605C10.4426 8.82697 10.0644 8.82697 9.8312 9.0605L8.93457 9.95826V0.597998C8.93457 0.267739 8.66718 1.14441e-05 8.33734 1.14441e-05C8.0075 1.14441e-05 7.74011 0.267739 7.74011 0.597998V9.95829L6.84345 9.0605C6.61022 8.82697 6.23207 8.82697 5.99884 9.0605C5.76561 9.29402 5.76561 9.67265 5.99884 9.90618L7.91502 11.8248C7.92664 11.8364 7.9387 11.8475 7.95115 11.8581C8.05527 11.9466 8.19008 11.9999 8.33734 11.9999Z" fill={color}/>
</g>
</svg>
)


export  {UpDown}