import { useState } from 'react'
import { ReactComponent as Dashboard } from '../../assets/svgIcon/menuIcon/Dashboard.svg'
import { ReactComponent as Inventory } from '../../assets/svgIcon/menuIcon/Inventory.svg'
import { ReactComponent as Planner } from '../../assets/svgIcon/menuIcon/Planner.svg'
import { ReactComponent as Orders } from '../../assets/svgIcon/menuIcon/Purchases.svg'
import { ReactComponent as Production } from '../../assets/svgIcon/menuIcon/Production.svg'
import { ReactComponent as Shipments } from '../../assets/svgIcon/menuIcon/Shipments.svg'
import { ReactComponent as Documents } from '../../assets/svgIcon/menuIcon/Documents.svg'
import { ReactComponent as Invoices } from '../../assets/svgIcon/menuIcon/Accounting.svg'
import { ReactComponent as Master } from '../../assets/svgIcon/menuIcon/Masters.svg'
import { ReactComponent as Integrations } from '../../assets/svgIcon/menuIcon/Integrations.svg'
import { ReactComponent as Admin } from '../../assets/svgIcon/menuIcon/Admin.svg'
import { ReactComponent as Logo } from '../../assets/svgIcon/menuIcon/orderlink.svg'
import { Link } from 'react-router-dom'
import { SubMenu } from '../SubMenu/SubMenu'
import styled from 'styled-components'

const allSubMenuItems = {
  'Inventory': [{ name: 'Summary', url: '/new/inventory' }, { name: ' Details', url: '/new/inventory/details' }, { name: 'Transactions', url: '/new/inventory/transactions' }],
  'Planner': [{ name: 'Planned Orders', url: '/planner/planned_order' }, { name: 'Projected Stocks', url: '/planner/projected_stock' }],
  'Orders': [{ name: 'Purchase Orders', url: '/new/purchase' }, { name: 'Purchase Lines', url: '/orders/purchase' }, { name: ' Transfer Orders', url: '/new/orders/transfer' }, { name: ' Transfer Lines', url: '/orders/transfer_line' }, { name: 'Allocations', url: '/new/orders/allocation' }],
  'Shipments': [{ name: 'International Shipment', url: '/new/shipment/international' }, { name: 'Shipment Items', url: '/shipment/item' }],
  'Reports': [{ name: 'Purchases', url: '/new/reports/purchase' }, { name: 'Transfers', url: '/new/reports/transfer' }, { name: 'Shipments', url: '/new/reports/shipment' },],
}
const listOfMenuItem = ['Dashboard', 'Inventory', 'Planner', 'Orders',
  'Shipments', 'Invoices', 'Documents', 'Master', 'Integrations', 'Admin'];

const listOfIcons = {
  Dashboard: { icon: <Dashboard />, url: '#' }, Inventory: { icon: <Inventory />, url: '#' }, Planner: { icon: <Planner />, url: '#' }, Orders: { icon: <Orders />, url: '#' }, Production: { icon: <Production />, url: '#' },
  Shipments: { icon: <Shipments />, url: '#' }, Documents: { icon: <Documents />, url: '/file-manager' }, Invoices: { icon: <Invoices />, url: '/invoices' }, Master: { icon: <Master />, url: '/master' },
  Integrations: { icon: <Integrations />, url: '/integration' }, Admin: { icon: <Admin />, url: '#' }, Reports: { icon: <h3>Reports</h3>, url: '#' }
}

export function MainMenu() {
  const [isOpenSubMenu, setisOpenSubMenu] = useState(false);
  const [selecetedMenu, setSelecetedMenu] = useState('')

  const closeSubMenu = () => {
    setisOpenSubMenu(false);
  }
  const toggleSubMenu = (menuItem) => {
    const clickedMenu = menuItem === "Dashboard" ? "Reports" : menuItem;
    setSelecetedMenu(clickedMenu)
    if (allSubMenuItems[clickedMenu]) {
      setisOpenSubMenu(true);
    }
    else {
      closeSubMenu()
    }
  }

  return (
    <MainMenuContainer >
      <nav className="mainMenu">
        <Link key={'logo'} className='logo' to='#'  > <Logo /> </Link>
        <div className="menuContainer">
          {listOfMenuItem.map(item => (
            <Link onClick={() => toggleSubMenu(item)} data-menu={item} key={item} className={item === selecetedMenu ? `iconWrapper activeMenu` : 'iconWrapper'} to={listOfIcons[item].url}  > {listOfIcons[item].icon} <h2>{item}</h2> </Link>
          ))}
        </div>
      </nav>
      {isOpenSubMenu && <SubMenu subMenuItems={allSubMenuItems[selecetedMenu]} closeSubMenu={closeSubMenu} selecetedMenu={selecetedMenu} />}
    </MainMenuContainer >
  )
}

const MainMenuContainer = styled.div`
  display: flex;
  > .mainMenu {
    display: flex;
    width: 88px;
    flex-direction: column;
    align-items: center;
    background: var(--DARK-GREY, #0e0e2c);
    height: 100vh;

    > .logo {
      display: flex;
      height: 72px;
      padding: 20px 28px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #22307a;
    }

    > .menuContainer {
      display: flex;
      padding: 0px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;

      h2 {
        margin: 0;
      }

      .iconWrapper {
        display: flex;
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        border: 2px solid transparent;
        width: 72px;

        &:hover {
          border-color: var(--Dark-Blue, #1249bf);
          background: var(--Dark-Grey, #262641);
        }
        &.activeMenu {
          border-color: #7182d6;
        }

        > h2 {
          color: var(--LIGHT-GREY, #8c8ca1);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; 
        }
      }
      > .iconWrapper.a:active {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`