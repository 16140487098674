import { useSelector } from 'react-redux';
import { InputText } from '../../../components/InputText/InputText';
import { DropDown, SearchDropDown } from '../DropDown/DropDown';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteRowIcon } from '../../../assets/svgIcon/shipment/DeleteRow.svg'

export const FilterForOneField = ({ ind, setDropDownValues, handleAddMoreCriteria, dropDownValues }) => {
    const { allFilterCondition, allFilterField, statusForFilter } = useSelector(state => state.newInterface);
    const { supplier, warehouse } = useSelector(state => state.report);
    const [filteredCondition, setFilteredCondition] = useState(allFilterCondition);
    const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false)
    const idForMultiSelectField = useMemo(() => [3, 6, 7, 8, 10, 11, 12, 14, 15, 16], []);
    const [selectedStatusItems, setSelectedStatusItems] = useState(idForMultiSelectField.includes(dropDownValues[ind]['field']) ? dropDownValues[ind].value : []);

    useEffect(() => {
        const filteredField = allFilterField.find(item => item.id === dropDownValues[ind]['field']);

        if (filteredField)
            setFilteredCondition(allFilterCondition.filter(item => filteredField.conditions.includes(item.id)));

        if (idForMultiSelectField.includes(dropDownValues[ind]['field'])) {
            setSelectedStatusItems(dropDownValues[ind].value)
        }
        // eslint-disable-next-line  
    }, [dropDownValues[ind]['field'], allFilterCondition, allFilterField])

    const handleInputValue = (e) => {
        setDropDownValues(state => {
            const allData = state.map((item, indexOfItem) => indexOfItem === ind ? { ...item, value: e.target.value } : item);
            return allData;
        })
    }

    const handleSelectStatusOption = (e) => {
        const listOfField = [6, 7, 8, 11, 12, 15, 16];
        const val = listOfField.includes(dropDownValues[ind].field) ? Number(e.target.value) : e.target.value;
        let items = [...selectedStatusItems];
        if (e.target.checked) {
            items.push(val)
        }
        else {
            items = items.filter(item => item !== val)
        }

        setSelectedStatusItems(items);
        const allValues = [...dropDownValues];
        allValues[ind]['value'] = items;
        setDropDownValues(allValues);
    }

    const selectItemsForMultiselect = () => {
        const idOfSelectedField = dropDownValues[ind].field;
        //for status
        if ([3, 10, 14].includes(idOfSelectedField)) {
            return statusForFilter;
        } else if ([7, 12].includes(idOfSelectedField)) {
            return supplier;
        } else if ([6, 8, 11, 15, 16].includes(idOfSelectedField)) {
            return warehouse;
        }
        else {
            return [];
        }
    }

    const deleteRowOfFilter = () => {
        if (ind === 0 && dropDownValues.length === 1) return;
        setDropDownValues(state => state.filter((item, index) => ind !== index))
    }

    return (
        <div key={ind} className='filterForColumn search-dropdown-container'>
            <SearchDropDown index={ind} handleDropDown={setDropDownValues} isObject={true} allData={allFilterField} isSelected={allFilterField.find(item => item.id === dropDownValues[ind].field)?.name} fieldName="Field" />
            <SearchDropDown index={ind} handleDropDown={setDropDownValues} isObject={true} allData={filteredCondition} isSelected={filteredCondition.find(item => item.id === dropDownValues[ind].condition)?.name} fieldName="Condition" />
            {idForMultiSelectField.includes(dropDownValues[ind]['field']) ?
                <DropDown className="search-dropdown-container">
                    <label className="search-dropdown-label">Value</label>
                    <div
                        className="search-dropdown-header"
                        onClick={() => setIsStatusDropDownOpen(!isStatusDropDownOpen)}
                    >
                        {selectedStatusItems.length > 0 ? 'Selected' : 'Select'}
                        <span className="search-dropdown-arrow">&#9662;</span>
                    </div>
                    {isStatusDropDownOpen &&
                        <StatusDropDown statusWithId={selectItemsForMultiselect()} handleSelectStatusOption={handleSelectStatusOption} dropDownValues={dropDownValues[ind].value} />
                    }
                </DropDown>
                :
                <InputText labelOfInput="Value" placeholder="Type value here" text={dropDownValues[ind].value} setText={handleInputValue} />
            }
            <SearchDropDown index={ind} handleDropDown={setDropDownValues} options={["OR", "AND"]} fieldName="Criteria" handleAddMoreCriteria={handleAddMoreCriteria} isSelected={dropDownValues[ind].criteria} />

            <span className="iconWrapper deleteRow" title='Delete Row' onClick={deleteRowOfFilter}>
                <DeleteRowIcon />
            </span>
        </div>
    )
}

export const StatusDropDown = ({ statusWithId, handleSelectStatusOption, dropDownValues, index }) => {
    return <div className="search-dropdown-menu">
        <ul className="search-dropdown-options">
            {statusWithId?.map((item) => (
                <LabelForStatus className='search-dropdown-option' key={item.id} >
                    <input type="checkbox"
                        onChange={(e) => handleSelectStatusOption(e, index)}
                        checked={dropDownValues ? dropDownValues.some(id => id === item.id) : false}
                        value={item.id} />
                    {item.name}
                </LabelForStatus>
            ))}

        </ul>

    </div>

}

const LabelForStatus = styled.label`
    justify-content: start !important;
    gap: 8px;

`