import styled from "styled-components"
import { PopupModal } from "../../components/TopBar/PopupModal/PopupModal"
import { ReactComponent as Check } from '../../assets/svgIcon/listView/check.svg'

export const SelectSortOption = ({ sortOptions, selectedOption, closeModal, setIsSortModalOpen, valueOfColumn, handleSortByEta }) => {

    const handleSelect = (item) => {
        setIsSortModalOpen(state => {
            return { ...state, [valueOfColumn]: { isOpen: false, value: item } }
        });
        handleSortByEta(item, valueOfColumn)
    }
    const resetOption = () => {
        setIsSortModalOpen(state => {
            return { ...state, [valueOfColumn]: { isOpen: false, value: '' } }
        })
        handleSortByEta('reset')
    }

    return (
        <PopupModal styleOfModal={styleOfSortOptions} closeModal={closeModal}>
            <OptionsOfSelect className="optionsOfSelect">

                {
                    sortOptions.length > 0 && sortOptions.map(item =>
                        <div key={item} className={`optionItem ${selectedOption === item ? 'activeItem' : ''}`} onClick={() => handleSelect(item)}>
                            {item}

                            {selectedOption === item && <Check />}
                        </div>
                    )
                }

                <div className={`optionItem `} onClick={() => resetOption('reset')}>
                    Reset
                </div>
            </OptionsOfSelect>
        </PopupModal>
    )
}


export const styleOfSortOptions = {
    position: 'absolute', zIndex: 5,
    top: '2.5rem',
    left: 0,
};

export const OptionsOfSelect = styled.div` 
            display: flex;
            min-width: max-content;
            padding: 4px 0px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            border: 1px solid var(--PEARL, #ecf1f4);
            background-color: var(--Conditional-pop-over, #fff); 
            color: var(--GREY, #4a4a68);
            font-weight: 500;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.42px;

            /* drop-shadow/0.12+0.8+0.5 */
            box-shadow:
              0px 3px 6px -4px rgba(0, 0, 0, 0.12),
              0px 6px 16px 0px rgba(0, 0, 0, 0.08),
              0px 9px 28px 8px rgba(0, 0, 0, 0.05);

              >p {
                pointer-events: none;
                cursor: not-allowed;
                padding-left: 12px;
                color: var(--DARK-GREY, #0e0e2c);
                font-weight: 500;
                line-height: 22px;
                padding-top: 5px;
              }

            .optionItem {
              padding: 5px 12px;
              color: var(--DARK-GREY, #0e0e2c);
              font-weight: 400;
              line-height: 22px;
              gap: 8px;
              display: flex;
              align-self: stretch;
              align-items: center;
              justify-content: space-between;
              min-width: max-content;
              font-size: 13px;

              &:hover {
                color: #1249bf;
                cursor: pointer;
              }
            } 
`