import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import "../../../page/Shipment/Shipment.css"
import { OrderDetails } from '../../components/PurchaseOrderDetails/PurchaseOrderDetails'
import { useGetTransferOrderQuery } from '../../../../../store/apiSlice/orderApiSlice'
import { TopBarOfOrderDetails } from '../../components/PurchaseOrderDetails/TopBarOfOrderDetails'
import { DetailsOfOrder } from '../../components/PurchaseOrderDetails/DetailsOfOrder'
import { NavigationInDetails } from '../../components/PurchaseOrderDetails/NavigationInDetails'
import { PurchaseOrderLines } from '../../components/PurchaseOrderDetails/PurchaseOrderLines'

export const TransferOrderDetails = () => {
    const { order_id } = useParams();
    const { refetch } = useGetTransferOrderQuery(order_id);
    const { items, ...orderData } = useSelector(state => state.order.transferOrder || {})
    const orderType = "transfer";

    let orderDetails = {};
    if (orderData.from_warehouse) {
        orderDetails = {
            from_warehouse: orderData.from_warehouse.name,
            warehouse: orderData.to_warehouse.name,
            creator: orderData.created_by,
            create_date: orderData.date_of_creation,
            currencyName: orderData.currency.name,
            status: orderData.status,
        }
    }

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [])

    return (
        <OrderDetails>
            <TopBarOfOrderDetails headerName={"Transfer Order"} headerId={orderData.name} />
            <DetailsOfOrder order={orderDetails} />
            <NavigationInDetails />
            <PurchaseOrderLines orderType={orderType} po_line={items} />
        </OrderDetails>
    )
};


