import { useState } from 'react';
import { BlurModal } from '../../page/components/BlurModal/BlurModal'
import { GeneralModal } from '../GeneralModal/GeneralModal'
import styled from 'styled-components';

export const DeleteModal = ({ closeDeleteModal, handleDeleteModal, nameOfView, labelForConfirmCheck }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e) => {
        setIsChecked(e.target.checked)
    }
    return (
        <BlurModal>
            <GeneralModal isChecked={isChecked} titleOfModal='Delete Confirmation' btnName='Delete' closeModal={closeDeleteModal}
                handleModalWork={handleDeleteModal} widthOfGeneralModal='30vw'>
                <p>{nameOfView} </p>

                <LabelForConfirm className='confirmDeletion'><input type="checkbox" onChange={handleChange} checked={isChecked} />
                    {labelForConfirmCheck} </LabelForConfirm>
            </GeneralModal>
        </BlurModal>
    )
}

const LabelForConfirm = styled.label`
    display: flex;
    gap: 4px;
    align-items: center;
    background-color: rgb(240, 240, 240);
    padding: 0 4px;
    border: 4px;
    margin: 8px 0;
    font-size: 13px;
    font-weight: 400;
    width: max-content;

    input {
      cursor: pointer;
    }
`