import { useEffect, useState } from 'react'
import { ReactComponent as Close } from '../../../assets/svgIcon/listView/Close.svg'
import { ReactComponent as Lock } from '../../../assets/svgIcon/listView/lock.svg'
import { ReactComponent as DragMenu } from '../../../assets/svgIcon/listView/dragmenu.svg'
import { useSelector } from 'react-redux'
import { useCreateViewMutation, useUpdateViewMutation } from '../../../../../store/apiSlice/newInterfaceApiSlice'
import { orderTypeForUrl, selectState } from '../../utils/tableDataElements'

export function CreateView({ closeCreateView, boardView, editView, selectedCurrentView, orderType: orderTypeForCreate, setSelectedCurrentView }) {
    const [dropDownValue, setdropDownValue] = useState('status')
    const [createViewForCommon] = useCreateViewMutation();
    const [updateView] = useUpdateViewMutation();

    const [selectedViewItem, setSelectedViewItem] = useState([]);
    const [viewName, setViewName] = useState('');
    const { listView: listFromServer, boardView: boardViewFromServer } = useSelector(state => {
        if (orderTypeForCreate === "shipment") {
            return state.newInterface.allViews;
        }
        else if (orderTypeForCreate === "transfer") {
            return state.newTransferOrder.allViews;
        }
        return state.newPurchaseOrder.allViews;
    });

    const { milestoneList } = useSelector(state => state[selectState[orderTypeForCreate]]);
    const [isPersonal, setIsPersonal] = useState(false);

    const columnNames = boardView ? (dropDownValue === 'milestone' ? milestoneList.map(item => item.name) : boardViewFromServer.status.allItemsName) : listFromServer.allItemsName;

    const handleSelectViewOption = (e) => {
        if (e.target.checked) {
            setSelectedViewItem([...selectedViewItem, { columnName: e.target.value, checked: true, id: (dropDownValue === 'milestone' ? e.target.getAttribute('data-id') : null) }])
        }
        else {
            setSelectedViewItem(selectedViewItem.filter(item => item.columnName !== e.target.value))
        }
    }

    const handleSelectRightSide = (e) => {
        setSelectedViewItem(selectedViewItem.map((item) => {
            if (e.target.value === item.columnName) {
                item.checked = !item.checked;
            }
            return item;
        }))
    }

    const onlyCheckedSelectedColumnName = () => selectedViewItem.reduce((acc, item) => {
        if (item.checked === false) {
            return acc;
        }

        if (dropDownValue === 'milestone') {
            acc.push(item.id)
        }
        else {
            acc.push(item.columnName);
        }
        return acc;
    }, [])

    const handleCreateView = async (e) => {
        if (editView) {
            await updateView({
                id: selectedCurrentView.id,
                data: {
                    name: viewName,
                    sequence: onlyCheckedSelectedColumnName()
                }
            })
            setSelectedCurrentView({ ...selectedCurrentView, value: viewName })
        }
        else {
            createViewForCommon({
                is_personal: isPersonal,
                module: orderTypeForUrl[orderTypeForCreate].module,
                name: viewName,
                sequence: onlyCheckedSelectedColumnName(),
                type: boardView ? 'board' : 'list',
                sub_type: boardView ? dropDownValue : null

            })
        }

        closeCreateView(e, selectedViewItem);
    }

    const handleViewName = (e) => {
        setViewName(e.target.value);
    }

    const handlePersonalView = (e) => {
        if (e.target.value === 'personal') {
            setIsPersonal(true)
        }
        else {
            setIsPersonal(false)
        }
    }

    // Drag and Drop- set view items in order
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("dragIndex", index);
    };

    const handleDrop = (e, dropIndex) => {
        const dragIndex = e.dataTransfer.getData("dragIndex");
        if (dragIndex !== null && dragIndex !== dropIndex) {
            const updatedItems = [...selectedViewItem];
            const draggedItem = updatedItems.splice(dragIndex, 1)[0];
            updatedItems.splice(dropIndex, 0, draggedItem);
            setSelectedViewItem(updatedItems);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (editView) {
            setSelectedViewItem((boardView ? boardViewFromServer[selectedCurrentView.sub_type] : listFromServer)?.allViews[selectedCurrentView.value].map((item) => {
                if (selectedCurrentView.sub_type === 'milestone') {
                    let colomnName = item;
                    let condition = milestoneList.find(milestone => milestone.id === Number(item));
                    if (milestoneList.find(milestone => milestone.id === Number(item))) {
                        colomnName = condition.name;
                    }
                    return { columnName: colomnName, checked: true, id: item };
                }
                return { columnName: item, checked: true };
            })
            )
            setViewName(selectedCurrentView.value);
            setdropDownValue(selectedCurrentView.sub_type)
        }
        // eslint-disable-next-line
    }, [])


    const handleSelectForWarning = () => { };

    const handleDropDown = (e) => {
        setdropDownValue(e.target.value)
    };

    return (
        <div className='createView'>
            <header>
                {editView ? 'Edit' : 'Create'} View
                <Close onClick={closeCreateView} />
            </header>

            <main>
                <label>
                    <span>Name of the View </span>
                    <input onChange={handleViewName} value={viewName} placeholder='Enter view name' type="text" />
                </label>

                <div className="detailsOfView">
                    <div className="leftSide">
                        {!editView && <select onChange={handlePersonalView} className="selectBox" value={isPersonal ? 'personal' : 'organizational'} >
                            <option value='organizational'  >Organizational View</option>
                            <option value='personal'  >Personal View</option>
                        </select>}
                        <div onClick={handleSelectViewOption} className="availableColumn">
                            <p>Available Columns</p>
                            <div>
                                <label >
                                    <input type="checkbox" checked disabled />
                                    Project Name
                                </label>
                                {
                                    columnNames.map((item, ind) => (
                                        <label key={item} >
                                            <input type="checkbox" onChange={handleSelectForWarning} checked={selectedViewItem?.some(name => name.columnName === item)}
                                                data-id={dropDownValue === 'milestone' ? milestoneList[ind].id : null} value={item} />
                                            {item}
                                        </label>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="rightSide">
                        {boardView && <select onChange={handleDropDown} className="selectBox" value={dropDownValue} >
                            <option value='status'  >Status View</option>
                            <option value='milestone'  >MileStone View</option>
                        </select>}
                        <p>Selected Columns</p>
                        <div className="selectedColumns ">
                            <div className='item'>
                                <Lock />
                                Project Name
                            </div>
                            {selectedViewItem?.map((item, index) => (
                                <label
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, index)}
                                    key={item.columnName} className='item'>
                                    <DragMenu />
                                    <input type="checkbox" checked={item.checked} value={item.columnName} onChange={handleSelectRightSide}

                                    />
                                    {item.columnName}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </main>

            <footer>
                <button onClick={closeCreateView}>Cancel</button>
                <button className='createViewBtn' disabled={(viewName && selectedViewItem?.length) ? false : true} onClick={handleCreateView}>{editView ? 'Update' : 'Create'} View</button>
            </footer>

        </div>
    )
}
