import { PopupModal } from '../TopBar/PopupModal/PopupModal'
import { GeneralModal } from '../GeneralModal/GeneralModal'
import styled from 'styled-components'
import { colorcomb, colorNames } from './constant'
import { useAddMilestoneMutation, useUpdateMilestoneDetailsMutation } from '../../../../store/apiSlice/newInterfaceApiSlice'
import { useState } from 'react'
import { orderTypeForUrl } from '../../page/utils/tableDataElements'

export const CreateMilestone = ({ closeCreateMilestone, milestoneName, orderType }) => {
    const [updateMilestone] = useUpdateMilestoneDetailsMutation();
    const [milestoneData, setMilestoneData] = useState(milestoneName)
    const [addMilestone] = useAddMilestoneMutation();

    const handleInputChange = (e) => {
        if (e.target.name) {
            setMilestoneData(state => ({ ...state, [e.target.name]: e.target.value }))
        }
        else {
            setMilestoneData(state => ({ ...state, [e.target.getAttribute('data-name')]: e.target.title }))
        }
    }
    const handleCreateMilestone = async () => {
        closeCreateMilestone()
        if (milestoneData.id) {
            await updateMilestone({ id: milestoneData.id, data: { ...milestoneData } });
            return;
        }
        let dataForCreatingMilestone = { ...milestoneData };
        if (!milestoneData.color) dataForCreatingMilestone = { ...milestoneData, color: 'processing' }
        addMilestone({ ...dataForCreatingMilestone, module: orderTypeForUrl[orderType].module });
    }

    return (
        <>
            <PopupModal closeModal={closeCreateMilestone}>
                <GeneralModal titleOfModal='Create Milestone' btnName='Save' handleModalWork={handleCreateMilestone} closeModal={closeCreateMilestone} >
                    <HandleInput className="createMileStone">
                        <label>  Milestone Name:
                            <input type="text" placeholder='Type name' onChange={handleInputChange} name='name' value={milestoneData.name} />
                        </label>
                        <div className="colorPicker" onClick={handleInputChange}>
                            <p>Choose Color: {milestoneData.color} </p>
                            <div className="allColor">
                                {colorNames.map(item => {
                                    return (
                                        <div key={item} style={{ backgroundColor: colorcomb[item].color }} onClick={handleInputChange} data-name='color' title={item} className={`colorBox`}></div>
                                    )
                                })}
                            </div>
                        </div>
                    </HandleInput>
                </GeneralModal>
            </PopupModal>
        </>
    )
}

const HandleInput = styled.div` 
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 0px 16px;
    label {
        margin-bottom: 8px;
      > input {
        height: 24px;
        padding: 8px 4px 8px 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        border-radius: 4px;
        border: 1px solid var(--LIGHT-ASH, #b9b9c6);
        color: var(--DARK-GREY, #0e0e2c);

        &::placeholder {
          color: var(--LIGHT-ASH, #b9b9c6);
        }
      }
    }
    >.colorPicker{
        p{
            margin-bottom: 5px;
        }
        label{
        display: flex;
        gap: 8px;
    }
    .allColor{
        display:  flex;
        gap: 8px;
        padding: 4px;
        flex-wrap: wrap;
        .colorBox{
            width: 40px;
            height: 40px; 
            border-radius: 4px;
            cursor: pointer;
        }
    }
    }
`