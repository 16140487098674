import styled from 'styled-components'

export const BlurModal = ({ children }) => {
    return (
        <BlurModalConatainer>
            {children}
        </BlurModalConatainer>
    )
}

const BlurModalConatainer = styled.div`
   position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); 
  backdrop-filter: blur(1px); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
`