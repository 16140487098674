export const sortAllView = (data) => {
    const views = {
        list: {
            nameOfList: [],
            allViews: {}
        },
        status: {
            nameOfList: [],
            allViews: {}
        },
        milestone: {
            nameOfList: [],
            allViews: {}
        },
    };
    data.forEach(item => {
        if (item.sub_type) {
            views[item.sub_type['id']]['allViews'] = {
                ...views[item.sub_type['id']]['allViews'],
                [item.name]: item.sequence,
            };
            views[item.sub_type['id']].nameOfList.push({ name: item.name, id: item.id, is_view_rule_exists: Boolean(item.filter_criteria) })
        }
        else {
            views['list']['allViews'] = {
                ...views['list']['allViews'],
                [item.name]: item.sequence,
            };
            views['list'].nameOfList.push({ name: item.name, id: item.id, is_view_rule_exists: Boolean(item.filter_criteria) })
        }
    })

    return views;
}

export const filterOrderByMilestone = (data = [], milestoneList) => {
    const mapConsignments = {}
    const filterd = { Uncategorized: [] };
    milestoneList.forEach(item => {
        filterd[item.id] = [];
    });

    data.forEach(item => {
        mapConsignments[item.id] = item;
        if (item.milestone?.id) {
            filterd[item.milestone.id].push(item.id)
        }
        else {
            filterd.Uncategorized.push(item.id)
        }
    });
    return { filterd, mapConsignments };
}

export const trackDataByOrderType = {
    "shipment": {
        firstSubPart: "api_shipment",
        secondSubPart: "consignment_tracking",
        idForOrderType: "consignment"
    },
    "transfer": {
        firstSubPart: "api_order",
        secondSubPart: "transfer_order_tracking",
        idForOrderType: "transfer_order"
    },
    "purchase": {
        firstSubPart: "api_po",
        secondSubPart: "purchase_order_tracking",
        idForOrderType: "purchase_order"
    },
}