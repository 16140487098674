import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { PopupModal } from "../../../components/TopBar/PopupModal/PopupModal";
import { ReactComponent as AddNote } from '../../../assets/svgIcon/shipment/Addnote.svg'
import { useCreateConsignmentNoteMutation, useGetConsignmentNotesQuery } from "../../../../../store/apiSlice/shipmentApiSlice";
import { useDeleteNoteMutation, useUpdateNoteMutation } from "../../../../../store/apiSlice/newInterfaceApiSlice";
import { NotesList } from "./NotesList";
import { format } from "date-fns";
import { resetNotesList } from "../../../../../store/slice/shipmentSlice";
import { ShimmerUiForNotes } from "./ShimmerUiForNotes";
import { orderTypeForUrl, selectStateForNotes, selectStateForOrder } from "../../utils/tableDataElements";
import { useGetPurchaseOrderNotesQuery } from "../../../../../store/apiSlice/purchaseOrderApiSlice";
import { useGetTransferOrderNotesQuery } from "../../../../../store/apiSlice/orderApiSlice";

const users = [
  { id: 1, name: "Nar" },
  { id: 2, name: "Krishan" },
  { id: 3, name: "Avinash" },
];

export const NotesModal = ({ orderId, isNoteAvailable, orderType, getConsignmentByRule, selectedCurrentView, setOrderIdForNotes }) => {
  const [currentText, setCurrentText] = useState('');
  const { full_name } = useSelector(state => state.auth.user);
  const [lastUpdatedBy, setLastUpdatedBy] = useState(full_name);
  const [suggestions, setSuggestions] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [editId, setEditId] = useState(null)
  const textareaRef = useRef(null);
  const [createNote] = useCreateConsignmentNoteMutation();
  const [deleteNoteFromServer] = useDeleteNoteMutation();
  const [updateNote] = useUpdateNoteMutation();
  const dispatch = useDispatch();
  const { refetch: shipmentRefetch } = useGetConsignmentNotesQuery(`related_object_id=${orderId}&type=${selectStateForNotes[orderType]}`, {
    refetchOnMountOrArgChange: true, skip: orderType !== "shipment"
  });
  const { refetch: purchaseRefetch } = useGetPurchaseOrderNotesQuery(`related_object_id=${orderId}&type=${orderType}`, {
    refetchOnMountOrArgChange: true, skip: orderType !== "purchase"
  });
  const { refetch: transferRefetch } = useGetTransferOrderNotesQuery(`related_object_id=${orderId}&type=${orderType}`, {
    refetchOnMountOrArgChange: true, skip: orderType !== "transfer"
  });
  let refetch = null;
  refetch = shipmentRefetch;
  if (orderType === "purchase") {
    refetch = purchaseRefetch;
  } else if (orderType === 'transfer') {
    refetch = transferRefetch;
  }

  const notes = useSelector(state => state[selectStateForOrder[orderType]][selectStateForNotes[orderType]]?.notes)
  const notesList = useMemo(() => {
    return notes ? notes : [];
  }, [notes])


  const [bothList, setBothList] = useState({
    checkedList: [],
    unCheckedList: []
  })

  const handleInputChangeForTextarea = (event) => {
    const textarea = event.target;
    const newValue = textarea.value;
    setCurrentText(newValue);

    const lastWord = newValue.split(" ").pop();
    if (lastWord.startsWith("@")) {
      const query = lastWord.substring(1).toLowerCase();
      const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(query)
      );
      setSuggestions(filteredUsers);
    } else {
      setSuggestions([]);
    }
  };

  const closeSuggestionModal = () => {
    setSuggestions([]);
  }

  const handleUserSelect = (user) => {
    const words = currentText.split(" ");
    words.pop();
    const newInputValue = `${words.join(" ")} @${user.name} `;
    setCurrentText(newInputValue);
    setSuggestions([]);
    setTaggedUsers([...taggedUsers, user.name]);
  };

  const addNote = async () => {
    await createNote({ id: orderId, content: currentText, type: orderTypeForUrl[orderType].module, is_view_rule_exists: selectedCurrentView.is_view_rule_exists })
    refetch();
    if (selectedCurrentView.is_view_rule_exists) {
      getConsignmentByRule();
    }
  }

  const deleteNote = async (id) => {
    await deleteNoteFromServer({ id, is_view_rule_exists: selectedCurrentView.is_view_rule_exists })
    await refetch();
    if (notesList.length === 1) {
      setOrderIdForNotes(state => {
        return { ...state, is_note_available: false }
      })
    }

    if (selectedCurrentView.is_view_rule_exists) {
      getConsignmentByRule();
    }
  }

  const openEditBox = (item) => {
    setEditId(item.id);
    setCurrentText(item.content)
    textareaRef.current.focus()
  }

  const toggleEditBox = (item) => {
    if (editId) {
      setEditId(null);
      setCurrentText('')
    } else {
      setEditId(item.id);
      setCurrentText(item.content)
      textareaRef.current.focus()
    }
  }

  const handleEdit = async () => {
    if (editId) {
      await updateNote({ id: editId, data: { content: currentText } })
      refetch()
    } else {
      addNote()
    }
    setLastUpdatedBy(full_name);
    setCurrentText('');
    setEditId(null)
  };

  const handleCheckboxChange = async (e, id) => {
    await updateNote({ id, data: { is_done: e.target.checked } })
    refetch()

    setLastUpdatedBy(full_name);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleEdit();
    }
  };

  useEffect(() => {
    if (notesList.length > 0) {
      setBothList({
        checkedList: notesList.filter((item) => item.is_done),
        unCheckedList: notesList.filter((item) => !item.is_done)
      })
    }
    else {
      setBothList({
        checkedList: [],
        unCheckedList: []
      })
    }
  }, [notesList]);

  useEffect(() => {
    return () => {
      dispatch(resetNotesList());
    }
  }, [dispatch])


  if (isNoteAvailable || bothList.unCheckedList.length > 0 || bothList.checkedList.length > 0) {
    return <NoteContainer >
      {
        bothList.checkedList.length > 0 || bothList.unCheckedList.length > 0 ?
          <>
            {bothList.unCheckedList.length > 0 &&
              <NotesList
                listOfItem={bothList.unCheckedList}
                handleCheckboxChange={handleCheckboxChange}
                toggleEditBox={toggleEditBox}
                openEditBox={openEditBox}
                deleteNote={deleteNote}
              />
            }

            <div className="addNote">
              {suggestions.length > 0 && !editId && (
                <PopupModal styleOfModal={styleOfSuggestionForAdd} closeModal={closeSuggestionModal}>
                  <ul>
                    {suggestions.map((user) => (
                      <li
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        style={{ cursor: "pointer" }}
                      >
                        {user.name}
                      </li>
                    ))}
                  </ul>
                </PopupModal>
              )}

              <textarea
                ref={textareaRef}
                value={currentText}
                onChange={handleInputChangeForTextarea}
                placeholder={notesList.length ? "Type your note here..." : "Looking for details? Start by entering your first note!"}
                onKeyDown={handleKeyDown}
                rows='4'
              />
              <div className="addBtnList">
                <span className="iconWrapper addNoteBtn" title="Add note" onClick={handleEdit}><AddNote /> </span>
              </div>
            </div>

            {bothList.checkedList.length > 0 &&
              <>
                <hr />
                <NotesList
                  listOfItem={bothList.checkedList}
                  handleCheckboxChange={handleCheckboxChange}
                  toggleEditBox={toggleEditBox}
                  openEditBox={openEditBox}
                  deleteNote={deleteNote}
                  classForCheckedNotes='checkedNoteList'
                />
              </>
            }
            <p style={{ marginTop: "10px" }}>Last updated by: {lastUpdatedBy} at {format(new Date(), ' hh:mm a')}</p>
          </>
          :
          <ShimmerUiForNotes />
      }
    </NoteContainer>
  }

  return <NoteContainer>
    <div className="addNote">
      {suggestions.length > 0 && !editId && (
        <PopupModal styleOfModal={styleOfSuggestionForAdd} closeModal={closeSuggestionModal}>
          <ul>
            {suggestions.map((user) => (
              <li
                key={user.id}
                onClick={() => handleUserSelect(user)}
                style={{ cursor: "pointer" }}
              >
                {user.name}
              </li>
            ))}
          </ul>
        </PopupModal>
      )}

      <textarea
        ref={textareaRef}
        value={currentText}
        onChange={handleInputChangeForTextarea}
        placeholder={notesList.length ? "Type your note here..." : "Looking for details? Start by entering your first note!"}
        onKeyDown={handleKeyDown}
        rows='4'
      />
      <div className="addBtnList">
        <span className="iconWrapper addNoteBtn" title="Add note" onClick={handleEdit}><AddNote /> </span>
      </div>
    </div>
  </NoteContainer>
}

const styleOfSuggestionForAdd = {
  position: 'absolute',
  backgroundColor: 'white',
  width: '20vw',
  paddingTop: '8px',
  zIndex: 1,
  top: '5vh'
}

const NoteContainer = styled.div` 
  >.addNote{
    display: flex;
    gap: 8px;
    position: relative;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--LIGHT-ASH, #B9B9C6);
    background-color: var(--Cloud, #FCFDFE);
    padding: 8px 4px 8px 12px;

    textarea{
    overflow-y: auto;
    scrollbar-width: thin;
    border: none;
    outline: none;
    color: var(--GREY, #4A4A68);
    font-weight: 400;
    line-height: 22px;
    }
    .addNoteBtn{
      padding: 4px 12px;
      margin-right: 4px;
      background-color: #2060EA;
      color: white;
      &:hover{
        background-color: #0855fa;
      }
    }
    >.addBtnList{
      display: flex;
      justify-content: flex-end;
    }
  }
  >.noteList{
      
    padding: 8px 0;
    list-style: none;
    max-height: 25vh;
    overflow-y: auto;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    gap: 4px;

    li{
      display: flex;
      gap: 8px;
      position: relative;
      align-items: start;
    }
  }
  .checkedNoteList{
    max-height: 13vh;
  }
  ul{
    padding-left: 0;

  >li{
    list-style: none;
    cursor: pointer;
    margin-bottom: 4px;

    .checkBox{
      margin-top: 0.3em;
    }

    .listInput{
      width: 100%;
      border: 1px solid transparent;
      outline: none;
      border-radius: 4px;
      border: 1px solid transparent;
      padding-left: 8px;

      &:focus{
        border-color : var(--DARK-BLUE, #1249BF);
      }
    }
    .content{
      display: flex;
      justify-content: space-between;
      flex: 1;
      
      span{
          font-weight: 400;
          line-height: 1.5;

          .updatedBy{
            text-decoration: underline;
            font-weight: 300;
            font-size: 13px;
            margin: 0 4px;
          }
      }

      .btnGroup{
        display: flex;
        gap: 8px;
        
      }

      .checkedItem{
        text-decoration: line-through;
      }

      button{
        border: 1px solid transparent;
        border-radius: 4px;
        height: fit-content;
      }
    }
  }
}
`