import { BlurModal } from "../components/BlurModal/BlurModal"
import { ModalHeader } from "../components/PurchaseOrderDetails/TrackingStatus"
import { Button } from "../components/Button/Button"
import { useSelector } from "react-redux"
import { useGetTransferOrderLineStatusQuery, useUpdatePurchaseOrderAllocationStatusMutation, useUpdateTransferOrderAllocationStatusMutation } from "../../../../store/apiSlice/orderApiSlice"
import { useState } from "react"
import { useNotification } from "../../../../hooks/useNotification"
import { useGetPurchaseOrderLineStatusQuery } from "../../../../store/apiSlice/purchaseOrderApiSlice"
import { selectStateForOrder } from "../utils/tableDataElements"
import { AddToShipmentCss, SelectShipment } from "./AddToShipment"


export const UpdateStatus = ({ closeModal, ordersName, allocation, setSelectedOrder }) => {
    useGetPurchaseOrderLineStatusQuery("", { refetchOnMountOrArgChange: true });
    useGetTransferOrderLineStatusQuery("", { refetchOnMountOrArgChange: true });

    const [updateStatusForPurchaseOrderLine] = useUpdatePurchaseOrderAllocationStatusMutation();
    const [updateStatusForTransferOrderLine] = useUpdateTransferOrderAllocationStatusMutation();

    const statusOfOrderline = useSelector(state => state[selectStateForOrder[allocation]][allocation === 'purchase' ? 'purchaseOrderLineStatus' : 'transferOrderLineStatus'])
    const [selectShipment, setSelectShipment] = useState(null)

    const notify = useNotification();

    const handleSubmit = async () => {
        if (!selectShipment) return;
        try {
            let res = null;
            if (allocation === 'purchase') {
                res = await updateStatusForPurchaseOrderLine({ ids: ordersName, status: selectShipment }).unwrap();
            } else {
                res = await updateStatusForTransferOrderLine({ ids: ordersName, status: selectShipment }).unwrap();

            }
            setSelectedOrder([])
            notify({
                type: "success",
                message: res.detail,
            });
            closeModal()

        } catch (error) {
            if (error.data?.detail) {
                notify({ type: "error", message: error.data.detail });
            }

        }
    }

    return (
        <BlurModal >
            <AddToShipmentCss>
                <ModalHeader headerName="Update Purchase Allocation Status" closeModal={closeModal} />
                <SelectShipment consignmentsSelection={statusOfOrderline} setSelectShipment={setSelectShipment} />

                <Button btnName="Submit" handler={() => handleSubmit()} classOfBtn='submitBtn' />
            </AddToShipmentCss>


        </BlurModal>
    )
}
