import { useEffect, useRef, useState } from 'react'
import { ReactComponent as Close } from '../../../../assets/svgIcon/listView/Close.svg'
import { ReactComponent as Search } from '../../../../assets/svgIcon/listView/search.svg'
import { ReactComponent as Check } from '../../../../assets/svgIcon/listView/CheckForFilter.svg'
import styled from 'styled-components';

export function FilterModal({ title, fieldName, allOrderNames, filterOrderByName, closeFilterModal, placeholderForInput }) {
  const searchBoxRef = useRef(null);
  const widthOfSearchedOrder = useRef(null);
  const [isSearchedItemRender, setIsSearchedItemRender] = useState(false);
  const [isSelectedAllSearchOrder, setIsSelectedAllSearchOrder] = useState(false);
  const [searchedOrder, setSearchedOrder] = useState(allOrderNames);
  const [selectedSearchOrder, setSelectedSearchOrder] = useState([]);

  useEffect(() => {
    widthOfSearchedOrder.current = searchBoxRef.current.getBoundingClientRect().width - 90;
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    if (query.length > 0) {
      setIsSearchedItemRender(true);
      const searchedItems = allOrderNames.filter(name => name.toLowerCase().includes(query.toLowerCase()));
      setSearchedOrder(searchedItems);
      setSelectedSearchOrder(searchedItems);
      setIsSelectedAllSearchOrder(true);
    }
    else {
      setIsSearchedItemRender(false)
      setSearchedOrder([]);
      setSelectedSearchOrder([]);
      setIsSelectedAllSearchOrder(false)
    }

  }

  const handleOrderCheckbox = (e) => {
    if (e.target.tagName === 'LABEL') return;
    if (e.target.value === 'selectAll') {
      if (e.target.checked) {
        setIsSelectedAllSearchOrder(true);
        setSelectedSearchOrder([...searchedOrder]);
      }
      else {
        setIsSelectedAllSearchOrder(false);
        setSelectedSearchOrder([]);
      }
      return;
    }

    if (e.target.checked) {
      setSelectedSearchOrder([...selectedSearchOrder, e.target.value])
    }
    else {
      setSelectedSearchOrder(selectedSearchOrder.filter(name => name !== e.target.value))
      if (isSelectedAllSearchOrder) {
        setIsSelectedAllSearchOrder(false)
      }

    }
  };

  const handleCloseOfSearch = () => {
    setSelectedSearchOrder([]);
    setSearchedOrder([])
  }

  const afterItemSelectedRenderInContainer = () => {
    let elements = ``;
    if (selectedSearchOrder.length >= 2 && (selectedSearchOrder[0].length + selectedSearchOrder[1].length) * 7 < widthOfSearchedOrder.current) {
      elements = <>
        <div className='searchedTextBox'>
          <span className='searchedText'>
            {selectedSearchOrder[0]}
          </span>
          <span className='searchedText'>
            {selectedSearchOrder[1]}
          </span>
          {selectedSearchOrder.length > 2 && <span className='searchedText'>
            +{selectedSearchOrder.length - 2}
          </span>}
        </div>
        <Close onClick={handleCloseOfSearch} />
      </>

    } else {
      elements = <>
        <div className='searchedTextBox'>
          <span className='searchedText'>
            {selectedSearchOrder[0]}
          </span>
          {selectedSearchOrder.length !== 1 && <span className='searchedText'>
            +{selectedSearchOrder.length === 2 ? 1 : selectedSearchOrder.length - 2}
          </span>}
        </div>
        <Close onClick={handleCloseOfSearch} />
      </>
    }
    return elements;
  }

  const isCheckedBox = (name) => {
    return selectedSearchOrder.includes(name);
  }

  const applyFilters = () => {
    filterOrderByName(selectedSearchOrder, fieldName);
    closeFilterModal();
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      applyFilters();
    }
  };

  return (
    <FilterModalCss className='filterModal'>
      <h3> {title}  <Close onClick={closeFilterModal} /> </h3>

      <SearchContainerCss ref={searchBoxRef} className="searchContainer">
        {!isSearchedItemRender && selectedSearchOrder.length ?
          afterItemSelectedRenderInContainer()
          : <>
            <input onChange={handleSearch} onClick={() => setIsSearchedItemRender(!isSearchedItemRender)} type="text"
              onKeyDown={handleKeyDown}
              placeholder={` ${placeholderForInput}`} />
            <div className="searchIcon">
              {selectedSearchOrder.length > 0 ?
                <Check onClick={() => setIsSearchedItemRender(!isSearchedItemRender)} />
                :
                <Search />
              }
            </div>
          </>

        }
        {(isSearchedItemRender && searchedOrder.length > 0) &&
          <div onClick={handleOrderCheckbox} className="searchedOrder">
            <label className="searchedItem" >
              <input type="checkbox" checked={isSelectedAllSearchOrder} onChange={() => ''} value='selectAll' name="selectAll" id="" />
              Select All
            </label>
            {searchedOrder.map(name => (
              <label key={name} className="searchedItem" name='label'>
                <input type="checkbox" value={name} checked={isCheckedBox(name)} id="" onChange={() => ''} />
                {name}
              </label>
            ))}
          </div>}
      </SearchContainerCss>
      <div className="btnGrp">
        <button onClick={closeFilterModal} className='btnForModal'>Cancel</button>
        <button className={selectedSearchOrder.length ? 'btnForModal applyBtn activeBtn' : 'btnForModal applyBtn'} onClick={applyFilters} disabled={selectedSearchOrder.length === 0} >Apply Filter</button>
      </div>
    </FilterModalCss>
  )
}

const FilterModalCss = styled.div`
  width: 16vw;
  border: 1px solid #ecf1f4;
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
  top: 3rem;
  background-color: white;
  z-index: 1;

  > h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 13px 9px 16px;
    background-color: #f9fafd;
    border: 1px solid #e9edf5;
    font-weight: 600;
    line-height: 24px;
    color: #0e0e2c;
  }

  .btnGrp {
    display: flex;
    justify-content: end;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    height: 32px;
    gap: 10px;
    margin: 0 17px 16px 0;

    > .btnForModal {
      padding: 4px 15px;
      background-color: #f7f8f9;
      border-radius: 4px;
      border: 1px solid transparent;
    }
    .applyBtn {
      color: rgba(0, 0, 0, 0.25);
    }
    .activeBtn {
      background-color: #2060ea;
      color: white;
    }
  }
          
`

export const SearchContainerCss = styled.div` 
    margin: 19px 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c7d0;
    border-radius: 4px;
    padding: 4px;
    padding-left: 12px;
    gap: 8px;
    height: 24px;
    position: relative;

    svg {
      cursor: pointer;
    }

    > .searchedTextBox {
      display: flex;
      gap: 9px;
      > .searchedText {
        color: var(--GREY, #4a4a68);
        text-align: center;
        font-feature-settings:
          "liga" off,
          "clig" off;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        background-color: #f4ddde;
        height: 16px;
        padding: 0px 8px 0px 4px;
        display: flex;
        align-items: center;
        border-radius: 4px;
      }
    }

    > input {
      border: none;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      color: #b9b9c6;
      &:focus {
        outline: none;
        color: #0e0e2c;
      }
    }

    .searchIcon {
      width: 24px;
    }
    > .searchedOrder {
      width: 100%;
      position: absolute;
      background-color: white;
      border: 1px solid salmon;
      left: 0;
      top: 30px;
      padding: 4px 0px;
      border-radius: 4px;
      border: 1px solid var(--PEARL, #ecf1f4);
      background: var(--Conditional-pop-over, #fff);
      height: 17rem;
      overflow-y: scroll;

      /* drop-shadow/0.12+0.8+0.5 */
      box-shadow:
        0px 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 9px 28px 8px rgba(0, 0, 0, 0.05);

      > .searchedItem {
        display: flex;
        padding: 5px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        color: var(--GREY, #4a4a68);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        > input {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          border: 1px solid var(--LIGHT-GREY, #8c8ca1);
        }
      }
    }
  
`
