
export const DetailsOfRule = () => {
    return (
        <div className="detailsOfRule">
            <h3>Information</h3>
            <ul className="points">
                <li className='point'> Define custom criteria to refine your filter.</li>
                <li className='point'> Use AND/OR condition to create a criteria pattern.</li>
                <li className='point'> Save the rule filters for future use.</li>
            </ul>
        </div>
    )
}

