import { format } from "date-fns"
import { Status } from "../Status/Status"

export const DetailsOfOrder = ({ order, orderType, containers }) => {
    if (!order.status) return null;
    return (
        <div className='detailsOfOrder'>

            {order.name && <DetailItem valueOfItem={order.name} headerName="Name" />}
            {order.asn && <DetailItem valueOfItem={order.asn} headerName="ASN" />}
            {order.supplierName && <DetailItem valueOfItem={order.supplierName} headerName="Supplier" />}
            {order.from_warehouse && <DetailItem valueOfItem={order.from_warehouse} headerName="From Warehouse" />}
            {order.wh_opr_type && <DetailItem valueOfItem={`${order.wh_opr_type.warehouse.name} : ${order.wh_opr_type.name}`} headerName="Deliver To" />}
            {order.warehouse && <DetailItem valueOfItem={`${order.warehouse} `} headerName="Deliver To" />}
            {order.creator && <DetailItem valueOfItem={order.creator} headerName="Created By" />}
            <DetailItem valueOfItem={order.create_date ? format(order.create_date, 'MMM dd yyyy; hh:mm a') : 'No ETA'} headerName="Date of Creation" />
            {order.currencyName && <DetailItem valueOfItem={order.currencyName} headerName="Currency" />}
            {order.uom && <DetailItem valueOfItem={order.uom} headerName="Pack UOM " />}
            {order.totalPack && <DetailItem valueOfItem={order.totalPack} headerName="Total Pack Qty" />}
            <StatusTrack statusName={order.status} />
        </div>
    )
}


const DetailItem = ({ valueOfItem, headerName }) => {
    return (
        <>
            <div className="detailItem">
                <div className="valueOfItem">{valueOfItem} </div>
                <div className="headerOfItem">{headerName} </div>
            </div>
            <div className="border"></div>
        </>
    )
}

const StatusTrack = ({ statusName }) => {
    return (
        <div className="detailItem">
            <Status statusName={statusName} />
            <div className="statusDetails">Status <span className="link">View Details</span></div>
        </div>
    )
}