import { createSlice } from "@reduxjs/toolkit";
import { filterOrderByMilestone, sortAllView } from "../utils";

const initialState = {
    boardView: 'false',
    allViews: {
        listView: {
            nameOfViews: [],
            allItemsName: ["SUPPLIER", "WAREHOUSE", "CREATE DATE", "ETA DATE", "CREATED BY", "STATUS"],
            allViews: {
                'Default View': ["SUPPLIER", "WAREHOUSE", "CREATE DATE", "ETA DATE", "CREATED BY", "STATUS"],
            }
        },
        boardView: {
            status: {
                nameOfViews: [],
                allItemsName: ['Fully Receipted', 'Partially Receipted', 'Cancelled',
                    "Fully Allocated",
                    "Not Allocated",
                    "Partially Dispatched",
                    "Fully Dispatched"],
                allViews: {
                    'Default View': ['Fully Receipted', 'Partially Receipted', 'Cancelled',
                        "Fully Allocated",
                        "Not Allocated",],
                }
            },
            milestone: {
                nameOfViews: [],
                allItemsName: [],
                allViews: {

                }
            }
        },
    },

    singleView: {},
    milestoneOrders: {},
    milestoneList: [],
    filterShipmentByMilestone: {},
    consignmentMappedById: {},

    allFilterField: [],
    allFilterCondition: [],
    filteredConsignmentByRule: null

};

const newPurchaseOrderSlice = createSlice({
    name: "newPurchaseOrder",
    initialState,
    reducers: {
        setMilestoneOrdersForPurchase: (state, action) => {
            state.milestoneList = action.payload;
        },

        setAllViewsForPurchase: (state, action) => {
            const data = action.payload;
            const views = sortAllView(data);
            state.allViews = {
                listView: {
                    ...state.allViews.listView,
                    nameOfViews: views.list.nameOfList,
                    allViews: { ...state.allViews.listView.allViews, ...views.list.allViews }
                },
                boardView: {
                    status: {
                        ...state.allViews.boardView.status,
                        nameOfViews: views.status.nameOfList,
                        allViews: { ...state.allViews.boardView.status.allViews, ...views.status.allViews }
                    },
                    milestone: {
                        nameOfViews: views.milestone.nameOfList,
                        allViews: views.milestone.allViews
                    }
                },
                completeListOfViews: action.payload,
            }
        },
        handleFilterPurchaseByMilestone: (state, action) => {
            const { filterd, mapConsignments } = filterOrderByMilestone(action.payload, state.milestoneList);
            state.consignmentMappedById = mapConsignments;
            state.filterShipmentByMilestone = filterd;
        },
        updateFilterPurchaseByMilestone: (state, action) => {
            const filterd = { ...state.filterShipmentByMilestone };

            filterd[action.payload.sourceIndex] = filterd[action.payload.sourceIndex].filter(item => item !== action.payload.data.ids[0]);
            filterd[action.payload.id] = [...action.payload.data.ids, ...filterd[action.payload.id]]

            state.filterShipmentByMilestone = filterd;
        },

    },
});

export const {
    setMilestoneOrders,
    setAllViewsForPurchase,
    setMilestoneOrdersForPurchase,
    handleFilterPurchaseByMilestone,
    updateFilterPurchaseByMilestone
} = newPurchaseOrderSlice.actions;

export default newPurchaseOrderSlice.reducer;
