import styled from "styled-components";
import { ReactComponent as DragMenu } from '../../../../assets/svgIcon/listView/dragmenu.svg'
import { useState } from "react";
import { Button } from "../../Button/Button";
import { useUpdateCurrentViewOrDetailsMutation } from "../../../../../../store/apiSlice/newInterfaceApiSlice";
import { moduleForView } from "../../../utils/tableDataElements";

export const EditColumn = ({ headerList = [], setHeaderList, closeEditColumn, orderType }) => {
    const [headerListLocal, setHeaderListLocal] = useState(headerList);
    const [updateColumnDetails] = useUpdateCurrentViewOrDetailsMutation();

    const handleChange = (e) => {
        setHeaderListLocal(headerListLocal.map(item => item.columnName === e.target.value ? { ...item, checked: e.target.checked } : item))
    }

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("dragIndex", index);
    };

    const handleDrop = (e, dropIndex) => {
        const dragIndex = e.dataTransfer.getData("dragIndex");
        if (dragIndex !== null && dragIndex !== dropIndex) {
            const updatedItems = [...headerListLocal];
            const draggedItem = updatedItems.splice(dragIndex, 1)[0];
            updatedItems.splice(dropIndex, 0, draggedItem);
            setHeaderListLocal(updatedItems);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = () => {
        setHeaderList(headerListLocal);
        updateColumnDetails({
            column_for_detail: headerListLocal.reduce((acc, item) => {
                if (item.checked) acc.push(item.columnName);
                return acc;
            }, []), module: moduleForView[orderType]
        })
        closeEditColumn();
    }

    return (
        <EditColumnContainer className="editColumn">
            <div className="listContainer">
                {headerListLocal.map((item, index) => (

                    <label
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, index)}
                        key={item.columnName} className='item'>
                        <DragMenu />
                        <span>
                            <input type="checkbox" checked={item.checked} value={item.columnName} onChange={handleChange}

                            />
                            {item.columnName}

                        </span>
                    </label>
                ))}
            </div>

            <div className="buttonGroup">
                <Button btnName="Apply" handler={handleSubmit} classOfBtn="applyBtn" />

            </div>
        </EditColumnContainer>
    )
}

const EditColumnContainer = styled.div`
    background-color: white;
    >.listContainer{
        display: flex;
        gap: 8px;
        label{
            display: flex;
            gap: 8px;
            align-items: center;
            span{
                display: flex;
                gap: 4px;
            }
        }
    }
    .applyBtn{ 
        margin-top: 16px;
    }
`