import styled from 'styled-components'
import { useState } from "react";
import { useGetAllFilterConditionQuery, useGetAllFilterFieldQuery, useGetStatusForFilterRuleQuery } from '../../../../../store/apiSlice/newInterfaceApiSlice';
import { InputText } from '../../../components/InputText/InputText';
import { ReactComponent as EditIcon } from '../../../assets/svgIcon/shipment/Edit.svg'
import { ReactComponent as Check } from '../../../assets/svgIcon/listView/check.svg'
import { FilterForOneField } from './FilterForOneField';
import { DetailsOfRule } from './DetailsOfRule';
import { orderTypeForUrl } from '../../utils/tableDataElements';
import { apiUrlForStatus } from './constant';
import { useGetListOfSupplierQuery, useGetListOfWarehouseQuery } from '../../../../../store/apiSlice/reportApiSliceV2';

export const ViewRules = ({ dropDownValues, setDropDownValues, initialState, editPattern, setEditPattern, orderType }) => {
    useGetAllFilterConditionQuery();
    useGetAllFilterFieldQuery(orderTypeForUrl[orderType].module);
    useGetStatusForFilterRuleQuery(apiUrlForStatus[orderType]);
    useGetListOfSupplierQuery();
    useGetListOfWarehouseQuery();
    const [pattern, setPattern] = useState(editPattern);
    const [isEditPattern, setIsEditPattern] = useState(false);

    const openEditPattern = () => {
        setIsEditPattern(true)
    }
    const closeEditPattern = () => {
        setIsEditPattern(false)
    }

    const handleEditPattern = (e) => {
        setEditPattern(e.target.value)
        setPattern(e.target.value)
    }

    const handleAddMoreCriteria = (index, option) => {
        if (dropDownValues[index]['criteria'] === null) {
            setPattern(`(${pattern}  ${option}  ${index + 2})`);
            setEditPattern(`(${pattern}  ${option}  ${index + 2})`)
            setDropDownValues(state => {
                return [
                    ...state,
                    { ...initialState, index: index + 2 }
                ]
            })
        }
    }

    return (
        <ViewRulesContainer>
            {
                dropDownValues.map((item, ind) => (
                    <FilterForOneField
                        key={ind}
                        ind={ind} setDropDownValues={setDropDownValues} handleAddMoreCriteria={handleAddMoreCriteria}
                        dropDownValues={dropDownValues}
                        orderType={orderType}
                    />
                ))
            }



            {
                pattern !== 1 ?
                    <div className='pattern'>
                        Criteria Pattern: &nbsp;
                        {isEditPattern ?
                            <p>
                                <InputText
                                    text={editPattern} setText={handleEditPattern}
                                />
                                <span className='iconWrapper' onClick={closeEditPattern}><Check /></span>

                            </p>
                            : <p>{pattern} <span title='Edit' className='iconWrapper' onClick={openEditPattern}><EditIcon /></span> </p>}
                    </div>
                    :
                    <DetailsOfRule />


            }
        </ViewRulesContainer>
    )
}

const ViewRulesContainer = styled.div`
    .filterForColumn{
        display: flex;
        flex-wrap: wrap;

        }
        .labelForText{
            margin: 0 20px;
            background-color: white;
            gap: 5px;
            width: 210px;
            >input{
                width: 210px;

            }
        }
        .criteria{
            align-self: flex-end;
        }
        .pattern{
            display: flex;
            align-items: center;
            height: 42px;
            margin-left: 20px;

            .labelForText{
                width: auto;
            }
            >p{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 4px;
            }
        }
        .deleteRow{
            align-self: end;
            margin: 20px;
            cursor: pointer;
        }
        .detailsOfRule{
            margin-left: 20px;
            >ul{
                list-style-position: inside;
            }
        }

`

