import { ReactComponent as Clock } from '../../assets/svgIcon/listView/timer.svg'
import { showDateAndTime } from './showDateAndTime'

export const listDataElements = {

    shipment: (order, displayTime) => {
        const [eta, etaWithTime] = showDateAndTime(order.eta, displayTime) || [];
        const [creationDate, creationDateWithTime] = showDateAndTime(order.date_of_creation, displayTime);
        return {
            STATUS: <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
            'ETA': <td key='eta' className='estimatedDate' title={etaWithTime}><Clock /> {eta ? eta : 'No ETA'}</td>,
            'ORDER DATE': <td key='date_of_creation' className='estimatedDate' title={creationDateWithTime}><Clock /> {creationDate ? creationDate : ''}</td>,
            'CREATED BY': <td key='creator' className='userPic'><div className='creatorName'>{order.created_by ? order.created_by.full_name : <span className="dataNotPresent">Not Available</span>}</div></td>,
            'FROM WAREHOUSE': <td key='from warehouse' className='wareHouse'>{order.from_warehouses.length ? order.from_warehouses[0].name : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'TO WAREHOUSE': <td key='warehouse' className='wareHouse'>{order.to_warehouse}</td>,
            SUPPLIER: <td key='supplier' className='supplier'>{order.suppliers.length > 0 ? order.suppliers[0]['name'] : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'SHIPMENT NAME': <td key='shipment name' className='supplier'>{order.reference ? order.reference : <span className="dataNotPresent">Not Available</span>}</td>,
        }
    },
    purchase: (order, displayTime) => {
        const [eta, etaWithTime] = showDateAndTime(order.estimated_date, displayTime);
        const [creationDate, creationDateWithTime] = showDateAndTime(order.create_date, displayTime);
        return {
            STATUS: <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
            'ETA DATE': <td key='eta' className='estimatedDate' title={etaWithTime}><Clock /> {eta ? eta : 'No ETA'}</td>,
            'CREATE DATE': <td key='date_of_creation' className='estimatedDate' title={creationDateWithTime}><Clock /> {creationDate ? creationDate : ''}</td>,
            'CREATED BY': <td key='creator' className='userPic'><div className='creatorName'>{order.creator ? order.creator : <span className="dataNotPresent">Not Available</span>}</div></td>,
            'WAREHOUSE': <td key='from warehouse' className='wareHouse'>{order.warehouse ? order.warehouse : <span className="dataNotPresent">Not Applicable</span>}</td>,
            SUPPLIER: <td key='supplier' className='supplier'>{order.supplier ? order.supplier : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'PURCHASE NAME': <td key='shipment name' className='supplier'>{order.name ? order.name : <span className="dataNotPresent">Not Available</span>}</td>,
        }
    },
    transfer: (order, displayTime) => {
        const [eta, etaWithTime] = showDateAndTime(order.estimated_date, displayTime);
        const [creationDate, creationDateWithTime] = showDateAndTime(order.create_date, displayTime);
        return {
            STATUS: <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
            'CONSOLIDATION STATUS': <td key='status consildation' className='status'><span> <div className='statusBox'></div> {order.consolidation_status} </span>  </td>,
            'ETA': <td key='eta' className='estimatedDate' title={etaWithTime}><Clock /> {eta ? eta : 'No ETA'}</td>,
            'CREATE DATE': <td key='date_of_creation' className='estimatedDate' title={creationDateWithTime}><Clock /> {creationDate ? creationDate : ''}</td>,
            'CREATED BY': <td key='creator' className='userPic'><div className='creatorName'>{order.creator ? order.created_by.full_name : <span className="dataNotPresent">Not Available</span>}</div></td>,
            'FROM WAREHOUSE': <td key='from warehouse' className='wareHouse'>{order.from_warehouse ? order.from_warehouse : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'TO WAREHOUSE': <td key='warehouse' className='wareHouse'>{order.to_warehouse}</td>,
            'ERP SUPPLIER': <td key='supplier' className='supplier'>{order.erp_supplier ? order.erp_supplier : <span className="dataNotPresent">Not Applicable</span>}</td>,
        }
    },
    inventory: (order, displayTime) => {
        return {
            "CATEGORY": <td key="categ" className='category'>{order.product.categories[0] ? order.product.categories[0] : "--"}</td>,
            "PRODUCT": <td key="product" className=''>{order.product.number}</td>,
            "PRODUCT NAME": <td key="product name" className='supplier'>{order.product.name}</td>,
            "WAREHOUSE": <td key="warehouse" className='wareHouse'>{order.warehouse.name}</td>,
            "SELLABLE": <td key="sellable" className='sellable'>{Number().toFixed(2)}</td>,
            "ALLOACTED": <td key="allocated" className='sellable'>{Number(order.allocated).toFixed(2)}</td>,
            "RESERVED": <td key="reserved" className='sellable'>{Number(order.reserved).toFixed(2)}</td>,
            "ON HOLD": <td key="onHold" className='sellable'>{Number(order.on_hold).toFixed(2)}</td>,
            "REJECTED": <td key="rejected" className='sellable'>{Number(order.rejected).toFixed(2)}</td>,
            "TOTAL": <td key="total" className='sellable'>{Number(order.total).toFixed(2)}</td>,
            "NSP": <td key="nsp" className='sellable'>{Number(order.nsp).toFixed(2)}</td>,
            "AVG COST": <td key="avg" className='sellable'>{Number(order.average_cost).toFixed(2)}</td>,
            "STOCK VALUE": <td key="stock" className='stockValue'>{Number(order.total_value).toFixed(2)}</td>,

        }
    },
    allocation: (order, displayTime, allocationFor) => {
        const [time, etaWithTime] = showDateAndTime(order.eta, displayTime);
        return {
            "ORD NO": <td key="ORD NO" className="projectName">{order[selectNameForAllocation[allocationFor]] ? order[selectNameForAllocation[allocationFor]].name : '--'}</td>,
            "PRODUCT": <td key="product" className='sellable'>{order.product.number}</td>,
            "SUPPLIER": <td key="SUPPlier" className="supplier">{order[selectNameForAllocation[allocationFor]]?.supplier}</td>,
            "FROM WAREHOUSE": order[selectNameForAllocation[allocationFor]] && <td key="warehouse from" className='wareHouse'>{order[selectNameForAllocation[allocationFor]].from_warehouse?.name}</td>,
            "TO WAREHOUSE": order[selectNameForAllocation[allocationFor]] && <td key="warehouse" className='wareHouse'>{order[selectNameForAllocation[allocationFor]].to_warehouse}</td>,
            "REQUIRED DATE": <td key="eta" className="dispatched" title={etaWithTime}> {time ? <> <Clock /> {time}</> : '--'}</td>,
            "REQUIRED QTY": <td className="dispatched" key="required qty">{Number(order.ordered_qty).toFixed(2)}</td>,
            "ALLOCATABLE QTY": <td className="wareHouse" key="allocatable">{Number(order.allocatable).toFixed(2)}</td>,
            "ALLOCATED QTY": <td className="dispatched" key="alloc">{Number(order.allocated_qty).toFixed(2)}</td>,
            "DISPATCHED QTY": <td className="dispatched" key="dis">{Number(order.dispatched_qty).toFixed(2)}</td>,
            "ALLOCATED VALUE": <td className="allocatedVal" key="allvalue">$ {Number(order.allocated_value).toFixed(2)}</td>,
            "ALLOCATED CBM": <td className="dispatched" key="allocatedcbm">{Number(order.allocated_cbm).toFixed(2)}</td>,
            "STATUS": <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,

        }
    },
}

const selectNameForAllocation = { purchase: "purchase_order", transfer: "transfer_order" }

export const listOfClasses = {
    'STATUS': 'statusHeader', 'ETA DATE': 'estimatedDate', 'CREATE DATE': 'estimatedDate', 'ETA': 'estimatedDate', 'ORDER DATE': 'estimatedDate', 'CREATED BY': 'userPic',
    'WAREHOUSE': 'wareHouse', 'FROM WAREHOUSE': 'wareHouse', 'TO WAREHOUSE': 'wareHouse', 'SUPPLIER': 'supplier', 'SHIPMENT NAME': 'supplier',
    'ERP SUPPLIER': 'supplier',
    "SELLABLE": "sellable", "ALLOACTED": "sellable", "RESERVED": "sellable", "ON HOLD": "sellable", "REJECTED": "sellable", "TOTAL": "sellable", "NSP": "sellable", "AVG COST": "sellable",
    "PRODUCT NAME": "supplier", "CATEGORY": "category", "STOCK VALUE": "stockValue", "ORD NO": "projectName"
    , "PRODUCT": "sellable", "REQUIRED DATE": "dispatched", "REQUIRED QTY": "dispatched", "ALLOCATED QTY": "dispatched", "DISPATCHED QTY": "dispatched", "ALLOCATED VALUE": "allocatedVal", "ALLOCATED CBM": "dispatched",
    "ALLOCATABLE QTY": "wareHouse",
};

export const orderTypeForUrl = {
    "purchase": {
        module: "purchase_order"
    },
    "shipment": {
        module: "consignment"
    },
    "transfer": {
        module: "transfer_order"
    },
}

export const selectState = {
    "purchase": "newPurchaseOrder",
    "shipment": "newInterface",
    "transfer": "newTransferOrder",
    "allocation": "newTransferOrder",
    "inventory": "newWarehouse",

}
export const dataForSelectedState = {
    "purchase": "purchaseOrders",
    "shipment": "consignments",
    "transfer": "transferOrders",
    "inventory": "stockOnHand",
    "allocation": "purchaseAllocations",
}

export const selectStateForOrder = {
    "purchase": "purchaseOrder",
    "shipment": "shipment",
    "transfer": "order",
    "inventory": "warehouse",
    "allocation": "order",
}
export const selectStateForNotes = {
    "purchase": "purchaseOrder",
    "shipment": "consignment",
    "transfer": "transferOrder",
}
export const headerForFilter = {
    "purchase": "Purchase",
    "shipment": "Shipment",
    "transfer": "Transfer",
}
export const moduleForView = {
    "purchase": "purchase",
    "shipment": "consignment",
    "transfer": "transfer",
}


export const colorByStatus = {
    'Fully Received': 'green', 'Seller Location': 'sky', 'Partially Received': 'orange', 'Cancelled': 'red',
}


export const countMapWithHeader = {
    "REQUIRED QTY": "ordered_qty",
    "ALLOCATED QTY": "allocated_qty",
    "ALLOCATABLE QTY": "allocatable",
    "DISPATCHED QTY": "dispatched_qty",
    "ALLOCATED VALUE": "allocated_value",
    "ALLOCATED CBM": "allocated_cbm",

}

export const totalCountForData = {
    allocation: (po_line) => {
        let totalCount = {
            "ordered_qty": 0,
            "allocated_qty": 0,
            "dispatched_qty": 0,
            "allocated_value": 0,
            "allocated_cbm": 0,
            "allocatable": 0,

        }
        po_line.forEach(item => {
            totalCount = {
                ordered_qty: totalCount.ordered_qty + Number(item.ordered_qty),
                dispatched_qty: totalCount.dispatched_qty + Number(item.dispatched_qty),
                allocated_qty: totalCount.allocated_qty + Number(item.allocated_qty),
                allocatable: totalCount.allocatable + Number(item.allocatable),
                allocated_value: totalCount.allocated_value + Number(item.allocated_value),
                allocated_cbm: totalCount.allocated_cbm + Number(item.allocated_cbm),
            }
        })
        return totalCount;
    },
}