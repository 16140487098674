import { createSlice } from "@reduxjs/toolkit";
import { sortAllView } from "../utils";

const initialState = {
    boardView: 'false',
    allViews: {
        listView: {
            nameOfViews: [],
            allItemsName: ["CATEGORY", "PRODUCT", "PRODUCT NAME", "WAREHOUSE", "SELLABLE", "ALLOACTED", "RESERVED", "ON HOLD", "REJECTED", "TOTAL", "NSP", "AVG COST", "STOCK VALUE"],
            allViews: {
                'Default View': ["PRODUCT NAME", "CATEGORY", "WAREHOUSE", "SELLABLE", "ALLOACTED", "RESERVED", "ON HOLD", "REJECTED", "TOTAL", "NSP", "AVG COST", "STOCK VALUE"],
            }
        },

        boardView: {
            status: {
                nameOfViews: [],
                allItemsName: [],
                allViews: {
                    'Default View': [],
                }
            },
            milestone: {
                nameOfViews: [],
                allItemsName: [],
                allViews: {

                }
            },
            completeListOfViews: []
        },

        singleView: {},
        consignmentMappedById: {},

        allFilterField: [],
        allFilterCondition: [],
        filteredConsignmentByRule: null
    }
};

const newWareHouseSlice = createSlice({
    name: "newWarehouse",
    initialState,
    reducers: {

        setAllViewsForWarehouse: (state, action) => {
            const data = action.payload;
            const views = sortAllView(data);
            state.allViews = {
                listView: {
                    ...state.allViews.listView,
                    nameOfViews: views.list.nameOfList,
                    allViews: { ...state.allViews.listView.allViews, ...views.list.allViews }
                },

                completeListOfViews: action.payload,
            }
        },

    },
});

export const {
    setAllViewsForWarehouse,
} = newWareHouseSlice.actions;

export default newWareHouseSlice.reducer;
