import { useState } from "react";
import { CreateMilestone } from "./CreateMilestone";
import { PopupModal } from "../TopBar/PopupModal/PopupModal";
import { GeneralModal } from "../GeneralModal/GeneralModal";
import { useSelector } from "react-redux";
import { ReactComponent as EditIcon } from '../../assets/svgIcon/shipment/Edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svgIcon/shipment/DeleteOutlined.svg'
import { useDeleteMilestoneMutation, useGetMilestonesQuery } from "../../../../store/apiSlice/newInterfaceApiSlice";
import styled from "styled-components";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import { orderTypeForUrl, selectState } from "../../page/utils/tableDataElements";

export const Milestone = ({ closeMilestoneModal, openMilestoneModal, isOpenMilestone, orderType }) => {
    const [milestoneName, setMilestoneName] = useState({ name: '', color: '' });
    const [isOpenCreateMilestone, setisOpenCreateMilestone] = useState(false);
    const [deleteMilestone] = useDeleteMilestoneMutation();
    useGetMilestonesQuery(new URLSearchParams(orderTypeForUrl[orderType]).toString());
    const { milestoneList } = useSelector(state => {
        if (state[selectState[orderType]])
            return state[selectState[orderType]];
        return [];
    })


    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
    const [deleteData, setDeleteData] = useState(null);

    const closeCreateMilestone = () => {
        setisOpenCreateMilestone(false)
        openMilestoneModal();
    }

    const handleMilestoneModal = () => {
        setisOpenCreateMilestone(true);
        closeMilestoneModal();
        setMilestoneName({ name: '', color: '' })
    }

    const handleCreateMilestone = () => {
        openMilestoneModal();
        closeCreateMilestone();
        setMilestoneName({ name: '', color: '' })
    }

    const handleEdit = (val, ind) => {
        closeMilestoneModal();
        setMilestoneName({ name: val.name, color: val.meta.color, id: ind });
        setisOpenCreateMilestone(true);
    }

    const closeDeleteModal = () => setIsOpenDeleteModal(false)
    const openDeleteModal = (id, name) => {
        setIsOpenDeleteModal(true)
        setDeleteData({ id, name })
        closeMilestoneModal()
    }

    const handleDeleteModal = () => {
        deleteMilestone(deleteData.id)
        closeDeleteModal();
    }

    return (
        <>
            {isOpenDeleteModal && <DeleteModal
                handleDeleteModal={handleDeleteModal}
                closeDeleteModal={closeDeleteModal}
                nameOfView={`Think carefully! Deleting this "${deleteData.name}" milestone is permanent, and the data will be lost forever.`}
                labelForConfirmCheck={`I understand that the data cannot be retrieved after deleting.`}
            />}
            <Container >
                {isOpenMilestone &&
                    <PopupModal closeModal={closeMilestoneModal}>
                        <GeneralModal titleOfModal='Milestones' btnName='Create' closeModal={closeMilestoneModal} handleModalWork={handleMilestoneModal} >
                            <h3>Milestone List</h3>
                            <div className="milestoneList">
                                {milestoneList?.map((item) => (
                                    <p key={item.id}>{item.name}
                                        <div className="btnGroup">
                                            <span className="iconWrapper" title="Edit" onClick={() => handleEdit(item, item.id)}><EditIcon /> </span>
                                            <span className="iconWrapper" title="Delete" onClick={() => openDeleteModal(item.id, item.name)}><DeleteIcon /> </span>
                                        </div>
                                    </p>
                                ))}
                            </div>
                        </GeneralModal>
                    </PopupModal>
                }

                {isOpenCreateMilestone && <CreateMilestone
                    closeCreateMilestone={closeCreateMilestone}
                    handleCreateMilestone={handleCreateMilestone}
                    milestoneName={{ ...milestoneName }}
                    setMilestoneName={setMilestoneName}
                    orderType={orderType}
                />}

            </Container>
        </>
    )
}

const Container = styled.div`
    color: var(--DARK-GREY, #0e0e2c);
    background-color: white;
    position: absolute;
    z-index: 15;
    height: 50vh;
    top: 4rem;
    font-weight: 300;
    h3{
        margin-bottom: 1rem;
    }
    .milestoneList{
        height: 200px;
        scrollbar-width: thin;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;

        p{
            display: flex;
            justify-content: space-between;
            
            .buttonGroup{
                display: flex;
                gap: 8px;
            }

            button{
                border: none;
            }
        }

    }
`
