import styled from "styled-components"
import { ReactComponent as Close } from '../../../assets/svgIcon/listView/Close.svg'
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers"
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export const TrackingStatus = ({ closeModal, containerNo, trackingStatusByContainer }) => {
    const trackRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            trackRef.current.style.right = 0;
        }, 10);
    }, [])

    return (
        <TrackingStatusCss ref={trackRef}>
            <div className="trackStatus">
                <ModalHeader closeModal={closeModal} headerName="Tracking Status" />

                <KTCard className="">
                    <Tracking containerNo={containerNo} trackingStatusByContainer={trackingStatusByContainer} />
                </KTCard>
            </div>
        </TrackingStatusCss>
    )
}

const TrackingStatusCss = styled.div`
    position: fixed; 
    left: 0;
    right: -100%;
    top: 0;  
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); 
    backdrop-filter: blur(1px); 
    display: flex;
    justify-content: end;
    z-index: 11;
    transition: right 0.3s ease-in-out;

  >.trackStatus{
    background-color: white;
    width: 65vw;

    .card-header{
        display: none;
    }
    .card-body{
        padding-top: 0;
    }
    
  }
`

export const ModalHeader = ({ headerName, closeModal }) => {
    return <ModalHeaderCss className="headerForSideDrawer">
        {headerName}
        <Close onClick={closeModal} />
    </ModalHeaderCss>
}
export const ModalHeaderCss = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 13px 9px 16px;
    background-color: #f9fafd;
    border: 1px solid #e9edf5;
    border-radius: 4px 4px 0 0;
    font-weight: 600;
    line-height: 24px;
    color: #0e0e2c;
    >svg{
        cursor: pointer;
    }
  
    `

const Tracking = ({ containerNo, trackingStatusByContainer }) => {
    const { items = [] } = useSelector(state => state.shipment.consignment) || {};

    const containers = items.filter(item => item.container?.id).map(item => item.container);

    const [container, setContainer] = useState(null);

    useEffect(() => {
        if (container) return;
        setContainer(containers?.[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containers]);

    useEffect(() => {
        if (!containerNo) return;

        const cntr = containers.find(c => c.container_no === containerNo);
        setContainer(cntr);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerNo])

    if (containers.length < 1) return;

    return (
        <>
            <KTCardBody>
                <iframe
                    title="Tracking"
                    src={`https://app.portcast.io/v2/container_tracking/embed/${container?.portcast_bl_book_id}`}
                    className="w-100 h-750px"
                >
                    <p>Your browser does not support iframes.</p>
                </iframe>
            </KTCardBody>
        </>
    );
};

export { Tracking };

