import styled from "styled-components"

export const Status = ({ classOfStatus, statusName }) => {
    return (
        <StatusWrapper className={`colorBox ${classOfStatus}`}><span className='tickBox'></span> <span>{statusName}</span> </StatusWrapper>
    )
}

const StatusWrapper = styled.div`
        line-height: 18px;
        font-weight: 500;
        color: var(--Green-500, #14804a);
        background-color: #e1fcef;
        border-radius: 4px;
        font-size: 12px;
        letter-spacing: 0.36px;
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 6px;
        flex-shrink: 0;
        width: max-content;

        > .tickBox {
          width: 6px;
          height: 6px;
          border-radius: 2px;
          background-color: #38a06c;
        }
      
`
