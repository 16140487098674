import { PopupModal } from "../../components/TopBar/PopupModal/PopupModal"
import { OptionsOfSelect } from "../components/SelectSortOption"
import { ReactComponent as Check } from '../../assets/svgIcon/listView/check.svg'

export const SelectAllocation = ({ closeModal, selectedOption, setAllocation }) => {

    const handleSelect = (item) => {
        setAllocation(item);
        closeModal()
    }

    return (
        <PopupModal styleOfModal={styleOfSortOptions} closeModal={closeModal}>
            <OptionsOfSelect className="optionsOfSelect">
                <p > Allocation </p>

                <div className={`optionItem ${selectedOption === 'purchase' ? 'activeItem' : ''}`} onClick={() => handleSelect('purchase')}>
                    Purchase Allocations

                    {selectedOption === 'purchase' && <Check />}
                </div>
                <div className={`optionItem ${selectedOption === 'transfer' ? 'activeItem' : ''}`} onClick={() => handleSelect('transfer')}>
                    Transfer Allocations

                    {selectedOption === 'transfer' && <Check />}
                </div>

            </OptionsOfSelect>
        </PopupModal>
    )
}

const styleOfSortOptions = {
    position: 'absolute', zIndex: 5,
    top: '4rem',
};