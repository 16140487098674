import { createSlice } from "@reduxjs/toolkit";
import { filterOrderByMilestone, sortAllView } from "../utils";

const boardViewForAllocation = {
    boardView: {
        status: {
            nameOfViews: [],
            allItemsName: [],
            allViews: {}
        },
        milestone: {
            nameOfViews: [],
            allItemsName: [],
            allViews: {}
        }
    }
}

const initialState = {
    boardView: 'false',
    allViews: {
        listView: {
            nameOfViews: [],
            allItemsName: ['ERP SUPPLIER', 'FROM WAREHOUSE', 'TO WAREHOUSE', 'CONSOLIDATION STATUS', 'STATUS'],
            allViews: {
                'Default View': ['ERP SUPPLIER', 'FROM WAREHOUSE', 'TO WAREHOUSE', 'CONSOLIDATION STATUS', 'STATUS'],
            }
        },
        boardView: {
            status: {
                nameOfViews: [],
                allItemsName: ["Draft", "Created", "Not Allocated", "Partially Allocated", "Fully Allocated", "Partially Cargo Ready", "Fully Cargo Ready", "Partially Dispatched", "Fully Dispatched", "Partially Received", "Fully Received", "Partially Receipted", "Fully Receipted", "Completed", "Cancelled"],
                allViews: {
                    'Default View': ['Fully Dispatched', 'Partially Receipted', 'Completed',
                        "Partially Allocated", "Not Allocated"],
                }
            },
            milestone: {
                nameOfViews: [],
                allItemsName: [],
                allViews: {

                }
            }
        },
    },

    allocation: {
        purchase: {
            listView: {
                nameOfViews: [],
                allItemsName: ["ORD NO", "PRODUCT", "SUPPLIER", "TO WAREHOUSE", "REQUIRED DATE", "REQUIRED QTY", "ALLOCATED QTY", "DISPATCHED QTY", "ALLOCATED VALUE", "ALLOCATED CBM", "STATUS"],
                allViews: {
                    'Default View': ["ORD NO", "PRODUCT", "SUPPLIER", "TO WAREHOUSE", "REQUIRED DATE", "REQUIRED QTY", "ALLOCATED QTY", "DISPATCHED QTY", "ALLOCATED VALUE", "ALLOCATED CBM", "STATUS"],
                }
            },
            ...boardViewForAllocation
        },
        transfer: {
            listView: {
                nameOfViews: [],
                allItemsName: ["ORD NO", "PRODUCT", "FROM WAREHOUSE", "TO WAREHOUSE", "REQUIRED DATE", "REQUIRED QTY", "ALLOCATABLE QTY", "ALLOCATED QTY", "DISPATCHED QTY", "ALLOCATED VALUE", "ALLOCATED CBM", "STATUS"],
                allViews: {
                    'Default View': ["ORD NO", "PRODUCT", "FROM WAREHOUSE", "TO WAREHOUSE", "REQUIRED DATE", "REQUIRED QTY", "ALLOCATABLE QTY", "ALLOCATED QTY", "DISPATCHED QTY", "ALLOCATED VALUE", "ALLOCATED CBM", "STATUS"],
                }
            },
            ...boardViewForAllocation
        }
    },
    orderlineMapById: {
        purchase: null,
        transfer: null
    },

    allTransferOrders: [],
    singleView: {},
    milestoneOrders: {},
    milestoneList: [],
    filterShipmentByMilestone: {},
    consignmentMappedById: {},

    allFilterField: [],
    allFilterCondition: [],
    filteredConsignmentByRule: null

};

const newTransferOrderSlice = createSlice({
    name: "newTransferOrder",
    initialState,
    reducers: {
        setMilestoneOrdersForTransfer: (state, action) => {
            state.milestoneList = action.payload;
        },
        setAllViewsForTransfer: (state, action) => {
            const data = action.payload;
            const views = sortAllView(data);
            state.allViews = {
                listView: {
                    ...state.allViews.listView,
                    nameOfViews: views.list.nameOfList,
                    allViews: { ...state.allViews.listView.allViews, ...views.list.allViews }
                },
                boardView: {
                    status: {
                        ...state.allViews.boardView.status,
                        nameOfViews: views.status.nameOfList,
                        allViews: { ...state.allViews.boardView.status.allViews, ...views.status.allViews }
                    },
                    milestone: {
                        nameOfViews: views.milestone.nameOfList,
                        allViews: views.milestone.allViews
                    }
                },
                completeListOfViews: action.payload,
            }
        },

        setAllTransferOrders: (state, action) => {
            state.allTransferOrders = [...state.allTransferOrders, ...action.payload];
        },
        setOrderlineMapById: (state, action) => {
            let purchase = {};
            action.payload.forEach((item) => {
                purchase[item.id] = item;
            })
            if (action.payload[0]['transfer_order']) {
                state.orderlineMapById.transfer = purchase
            } else {
                state.orderlineMapById.purchase = purchase;
            }
        },
        handleFilterTransferByMilestone: (state, action) => {
            const { filterd, mapConsignments } = filterOrderByMilestone(action.payload, state.milestoneList);
            state.consignmentMappedById = mapConsignments;
            state.filterShipmentByMilestone = filterd;
        },
        updateFilterTransferByMilestone: (state, action) => {
            const filterd = { ...state.filterShipmentByMilestone };

            filterd[action.payload.sourceIndex] = filterd[action.payload.sourceIndex].filter(item => item !== action.payload.data.ids[0]);
            filterd[action.payload.id] = [...action.payload.data.ids, ...filterd[action.payload.id]]

            state.filterShipmentByMilestone = filterd;
        },
    },
});

export const {
    setMilestoneOrdersForTransfer,
    setAllViewsForTransfer,
    handleFilterTransferByMilestone,
    updateFilterTransferByMilestone,
    setAllTransferOrders,
    setOrderlineMapById,
} = newTransferOrderSlice.actions;

export default newTransferOrderSlice.reducer;
