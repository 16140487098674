import { setNotification } from "../slice/miscSlice";
import { editMilestoneItem, setAllFilterCondition, setAllFilterField, setAllViews, setConsignmentsForRule, setMilestoneOrders, setSingleView, setStatusForRule, setViewAndDetailsColumns, updateFilterShipmentByMilestone } from "../slice/newInterfaceSlice";
import { setAllViewsForPurchase, setMilestoneOrdersForPurchase, updateFilterPurchaseByMilestone } from "../slice/purchaseOrderSliceV2";
import { setAllViewsForTransfer, setMilestoneOrdersForTransfer, updateFilterTransferByMilestone } from "../slice/TransferOrderSliceV2";
import { trackDataByOrderType } from "../utils";
import { apiSlice } from "./apiSlice";
import { orderApiSlice } from "./orderApiSlice";
import { purchaseOrderApiSlice } from "./purchaseOrderApiSlice";
import { shipmentApiSlice } from "./shipmentApiSlice";

export const newInterfaceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addMilestone: builder.mutation({
            query: (data) => ({
                url: `/api_interface/milestone/`,
                method: "POST",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(res =>
                    dispatch(newInterfaceApiSlice.util.invalidateTags(['milestonesUpdateList']))
                ).catch(err => { })
            },
        }),
        getMilestones: builder.query({
            query: params =>
                params ? `api_interface/milestone/?${params}` : "api_interface/milestone/",
            onQueryStarted: (params, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => {
                    if (params === "module=purchase_order") {
                        dispatch(setMilestoneOrdersForPurchase(result.data))
                    } else if (params === "module=transfer_order") {
                        dispatch(setMilestoneOrdersForTransfer(result.data))
                    }
                    else if (params === "module=consignment") {
                        dispatch(setMilestoneOrders(result.data));
                    }
                }).catch(error => { });
            },
            providesTags: _ => ['milestonesUpdateList'],
        }),
        updateMilestoneDetails: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_interface/milestone/${id}`,
                method: "PATCH",
                body: data,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled
                    .then(result => dispatch(editMilestoneItem(result.data)))
                    .catch(error => { });
                dispatch(newInterfaceApiSlice.util.invalidateTags(['milestonesUpdateList']))
            },
        }),
        deleteMilestone: builder.mutation({
            query: (id) => ({
                url: `/api_interface/milestone/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(
                    setNotification({
                        type: "success",
                        message: "Done! The milestone has been removed successfully..",
                    }),
                )
                dispatch(newInterfaceApiSlice.util.invalidateTags(['milestonesUpdateList']))
            },
        }),

        addTrackingInfo: builder.mutation({
            query: ({ orderType, orderId, ...data }) => ({
                url: `/${trackDataByOrderType[orderType].firstSubPart}/${trackDataByOrderType[orderType].secondSubPart}/`,
                method: "POST",
                body: { ...data, [trackDataByOrderType[orderType].idForOrderType]: orderId },
            }),
            onQueryStarted: ({ orderType }, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    if (orderType === "shipment") {
                        dispatch(shipmentApiSlice.util.invalidateTags(["consignmentList"]))
                    } else if (orderType === "purchase") {
                        dispatch(purchaseOrderApiSlice.util.invalidateTags(["purchaseOrderList"]))
                    }
                    else if (orderType === "transfer") {
                        dispatch(orderApiSlice.util.invalidateTags(["transferOrderList"]))
                    }
                }).catch(error => { });
            },
        }),

        updateTrackingDetails: builder.mutation({
            query: ({ id, data, orderType }) => {
                return ({
                    url: `/${trackDataByOrderType[orderType].firstSubPart}/${trackDataByOrderType[orderType].secondSubPart}/${id}`,
                    method: "PATCH",
                    body: data,
                })
            },
            onQueryStarted: ({ orderType }, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    if (orderType === "shipment") {
                        dispatch(shipmentApiSlice.util.invalidateTags(["consignmentList"]))
                    } else if (orderType === "purchase") {
                        dispatch(purchaseOrderApiSlice.util.invalidateTags(["purchaseOrderList"]))
                    }
                    else if (orderType === "transfer") {
                        dispatch(orderApiSlice.util.invalidateTags(["transferOrderList"]))
                    }
                }).catch(error => { });
            },
        }),

        deleteNote: builder.mutation({
            query: ({ id }) => ({
                url: `/api_chat/note/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: ({ is_view_rule_exists }, { dispatch, queryFulfilled, getState }) => {
                queryFulfilled.then(result => {
                    if (!is_view_rule_exists && getState().shipment.consignment?.notes?.length === 1) {
                        dispatch(shipmentApiSlice.util.invalidateTags(["consignmentList"]))
                    }
                }).catch(error => { });
            },
        }),
        updateNote: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_chat/note/${id}/`,
                method: "PATCH",
                body: data,
            }),
        }),
        createView: builder.mutation({
            query: (data) => ({
                url: `/api_interface/view/`,
                method: "POST",
                body: data,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
            },
        }),
        getView: builder.query({
            query: (params) => params ? `/api_interface/view/?${params}` : "api_interface/view/",
            onQueryStarted: (params, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => {
                    if (params === "module=purchase_order") {
                        dispatch(setAllViewsForPurchase(result.data))
                    } else if (params === "module=transfer_order") {
                        dispatch(setAllViewsForTransfer(result.data))
                    }
                    else if (params === "module=consignment") {
                        dispatch(setAllViews(result.data))
                    }
                }
                ).catch(error => { });
            },
            providesTags: _ => ["viewUpdate"],
        }),
        getSingleView: builder.query({
            query: (id) => `api_interface/view/${id}`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setSingleView(result.data))).catch(error => { });
            }
        }),
        updateView: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_interface/view/${id}/`,
                method: "PATCH",
                body: data,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
            },
        }),
        deleteView: builder.mutation({
            query: (id) => ({
                url: `/api_interface/view/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    dispatch(
                        setNotification({
                            type: "success",
                            message: "View successfully deleted—it's no longer in the list!",
                        }),
                    )
                    dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
                })
            },
        }),
        handleMilestoneView: builder.mutation({
            query: ({ id, data }) => ({
                url: id === "Uncategorized" ? `/api_interface/milestone/bulk_remove_relation` : `/api_interface/milestone/${Number(id)}/bulk_update_relation`,
                method: 'PUT',
                body: data
            }),
            onQueryStarted: (dataFromQuery, { dispatch }) => {
                const { data: { module } } = dataFromQuery;
                if (module === "purchase_order") {
                    dispatch(updateFilterPurchaseByMilestone(dataFromQuery))
                }
                else if (module === "consignment") {
                    dispatch(updateFilterShipmentByMilestone(dataFromQuery))
                }
                else if (module === "transfer_order") {
                    dispatch(updateFilterTransferByMilestone(dataFromQuery))
                }
            },
        }),
        getAllFilterCondition: builder.query({
            query: () => "/api_rule/filter_condition/",
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setAllFilterCondition(result.data))).catch(error => { });
            },
        }),
        getAllFilterField: builder.query({
            query: (params) => `/api_rule/filter_field/?module=${params}`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setAllFilterField(result.data))).catch(error => { });
            },
        }),
        getAllFilterCriteria: builder.query({
            query: () => "/api_rule/filter_criteria/",
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setAllFilterField(result.data))).catch(error => { });
            },
        }),
        createFilterCriteria: builder.mutation({
            query: (data) => ({
                url: `/api_rule/filter_criteria/`,
                method: "POST",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(res => dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))).catch(err => { });
            },
        }),
        updateFilterCriteria: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_rule/filter_criteria/${id}/`,
                method: "PUT",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(res => dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))).catch(err => { })
            },
        }),
        deleteFilterCriteria: builder.mutation({
            query: (id) => ({
                url: `/api_rule/filter_criteria/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    dispatch(
                        setNotification({
                            type: "success",
                            message: "View reset successfully—back to a fresh start!",
                        })
                    );
                    dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]));
                })
            },
        }),
        getFilterConsignmentsByRule: builder.query({
            query: id => `/api_shipment/consignment/?view_id=${id}`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setConsignmentsForRule(result.data))).catch(error => { });
            },
            providesTags: _ => ["filteredConsignmentList"],
        }),
        getCurrentViewDetails: builder.query({
            query: id => `/api_user/miscellaneous/get_default_preference/`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setViewAndDetailsColumns(result.data))).catch(error => { });
            },
            providesTags: _ => ["currentViewAndDetails"],
        }),
        updateCurrentViewOrDetails: builder.mutation({
            query: (data) => ({
                url: `/api_user/miscellaneous/set_default_preference/`,
                method: "PATCH",
                body: data,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(newInterfaceApiSlice.util.invalidateTags(["currentViewAndDetails"]))
            },
        }),
        getStatusForFilterRule: builder.query({
            query: params => params,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setStatusForRule(result.data))).catch(error => { });
            },
        }),


    }),
});
export const {
    useAddMilestoneMutation,
    useCreateViewMutation,
    useGetMilestonesQuery,
    useUpdateMilestoneDetailsMutation,
    useUpdateTrackingDetailsMutation,
    useUpdateNoteMutation,
    useDeleteMilestoneMutation,
    useAddTrackingInfoMutation,
    useDeleteNoteMutation,
    useGetViewQuery,
    useGetSingleViewQuery,
    useUpdateViewMutation,
    useDeleteViewMutation,
    useHandleMilestoneViewMutation,
    useGetAllFilterConditionQuery,
    useGetAllFilterFieldQuery,
    useGetAllFilterCriteriaQuery,
    useCreateFilterCriteriaMutation,
    useUpdateFilterCriteriaMutation,
    useDeleteFilterCriteriaMutation,
    useGetFilterConsignmentsByRuleQuery,
    useGetCurrentViewDetailsQuery,
    useUpdateCurrentViewOrDetailsMutation,
    useGetStatusForFilterRuleQuery,


} = newInterfaceApiSlice;
