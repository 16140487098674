import { MainMenu } from '../../Menu/MainMenu/MainMenu'
import TransferOrder from "./TransferOrder";
import { Route, Routes } from 'react-router-dom';
import { TransferOrderDetails } from './TransferOrderDetails/TransferOrderDetails';

export function TransferPage() {
    return (
        <div className='d-flex overflow-hidden'>
            <MainMenu />
            <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
                <TransferOrder />
            </div>
        </div>
    )
}


const Page = () => {
    return (
        <Routes>
            <Route path=":order_id" element={<TransferOrderDetails orderType="transfer" />} />
            <Route index element={<TransferOrder />} />
        </Routes>
    )
}

export default Page;