import { useEffect, useMemo, useState } from 'react'
import { FilterModal } from './FilterModal/FilterModal';
import { UpDown } from '../../../assets/svgIcon/listView/up-down-arrow'
import { ReactComponent as FilterIcon } from '../../../assets/svgIcon/listView/filter.svg'
import { ReactComponent as Close } from '../../../assets/svgIcon/listView/Close.svg'
import { Link } from 'react-router-dom';
import { countMapWithHeader, dataForSelectedState, headerForFilter, listDataElements, listOfClasses, selectStateForOrder, totalCountForData } from '../../utils/tableDataElements';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SelectSortOption } from '../SelectSortOption';
import { dataForSort, dateFields, nameOfFields } from './util';

export function ListView({ allOrderNames, rowsPerPage, shipmentOrders, tableHeaderList, handleSelectOrder, orderType, allocation, selectedOrderFromList = [] }) {
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const { displayTime } = useSelector(state => state.misc.settings);
  const { count: totalOrderCount } = useSelector(state => state[selectStateForOrder[orderType]][dataForSelectedState[orderType]]);
  const [selectedOrder, setSelectedOrder] = useState(shipmentOrders)
  const [isSortModalOpen, setIsSortModalOpen] = useState({
    eta: { isOpen: false, value: null },
    status: { isOpen: false, value: null },
    'product no': { isOpen: false, value: null },
  });

  const tableDataElements = (order) => {
    const allElements = listDataElements[orderType](order, displayTime, allocation);

    return tableHeaderList.map(header => (
      allElements[header.toUpperCase()]
    ))
  }

  const filterOrderByName = (filtered) => {
    setFilteredOrder(shipmentOrders.filter(order => {
      return filtered.find(name => name === order.name)
    }))
  }

  const clearFilter = () => {
    setFilteredOrder([]);
  }

  const closeFilterModal = () => {
    setIsFilterModalOpen(false)
  }
  useEffect(() => {
    if (shipmentOrders)
      setSelectedOrder(shipmentOrders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentOrders])

  useEffect(() => {
    const isSelectAll = rowsPerPage === totalOrderCount || (shipmentOrders.length === rowsPerPage && shipmentOrders.length !== 20)
    if (!isSelectAll) {
      setSelectedOrder(shipmentOrders.slice(0, rowsPerPage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  let totalCount = useMemo(() => {
    let count = null;
    if (orderType === "allocation")
      count = totalCountForData[orderType](selectedOrder);
    return count;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder])
  const demoFn = () => '';

  const handleEtaSort = (header) => {
    setIsSortModalOpen({ ...isSortModalOpen, [header]: { ...isSortModalOpen[header], isOpen: true } })
  }

  const handleSort = {
    eta: (filteredBy, valueOfCol) => {
      const arr = [...shipmentOrders];
      const nameOfField = nameOfFields[valueOfCol];

      if (filteredBy === 'reset') {
        setSelectedOrder(shipmentOrders);
        return;
      }

      if (filteredBy === "Oldest to Newest") {
        arr.sort((a, b) => new Date(a[nameOfField]) - new Date(b[nameOfField]));
      }
      else {
        arr.sort((a, b) => new Date(b[nameOfField]) - new Date(a[nameOfField]));
      }

      setSelectedOrder(arr)
    },

    status: (filteredBy, valueOfCol) => {
      const arr = [...shipmentOrders];
      const nameOfField = nameOfFields[valueOfCol];
      const selectValue = (item) => {
        if (valueOfCol === 'supplier' && orderType === 'shipment') {
          return item.suppliers[0]?.name || '';
        }
        else if (valueOfCol === 'from warehouse' && orderType === 'shipment') {
          return item.from_warehouses[0]?.name || '';
        }
        else if (valueOfCol === 'created by' && orderType === 'shipment') {
          return item.created_by?.full_name || '';
        }
        return item[nameOfField] || '';
      }

      if (filteredBy === 'reset') {
        setSelectedOrder(shipmentOrders);
        return;
      }

      if (filteredBy === "Ascending") {
        arr.sort((a, b) => selectValue(a).localeCompare(selectValue(b)));
      }
      else {
        arr.sort((a, b) => selectValue(b).localeCompare(selectValue(a)));
      }

      setSelectedOrder(arr)
    },
    'product no': (filteredBy) => {
      const arr = [...shipmentOrders];

      if (filteredBy === 'reset') {
        setSelectedOrder(shipmentOrders);
        return;
      }

      if (filteredBy === "Ascending") {
        arr.sort((a, b) => a.name.localeCompare(b.name));
      }
      else {
        arr.sort((a, b) => b.name.localeCompare(a.name));
      }

      setSelectedOrder(arr)
    },

  }

  const closeSelectSortOption = (headerLowerCase) => {
    setIsSortModalOpen({ ...isSortModalOpen, [headerLowerCase]: { ...isSortModalOpen[headerLowerCase], isOpen: false } })
  }

  return (
    <ListViewCss className='listView'>
      <table>
        <thead>
          <tr >
            <th className='checkBox'><input type="checkbox" /></th>
            {orderType !== "allocation" && <th className='projectName'>
              {filteredOrder.length ? <span className='closeFilter' title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                : <FilterIcon onClick={() => setIsFilterModalOpen(true)} />
              }
              {isFilterModalOpen && <FilterModal title={headerForFilter[orderType]} closeFilterModal={closeFilterModal} filterOrderByName={filterOrderByName} allOrderNames={allOrderNames}
                placeholderForInput={`${headerForFilter[orderType]} No`} />}
              {orderType === 'inventory' ? "PRODUCT" : orderType.toUpperCase()} NO

              {(isSortModalOpen['product no']?.isOpen) &&
                <SelectSortOption valueOfColumn={'product no'}
                  closeModal={() => closeSelectSortOption('product no')}
                  sortOptions={dataForSort['status']} selectedOption={isSortModalOpen['product no'].value} setIsSortModalOpen={setIsSortModalOpen} handleSortByEta={handleSort['product no']} />}

              <span onClick={() => handleEtaSort('product no')} > <UpDown color={isSortModalOpen['product no']?.value ? "#2060ea" : "#676879"} /></span>
            </th>}

            {tableHeaderList?.map((item) => {
              const header = item.toUpperCase();
              const headerLowerCase = item.toLowerCase();
              return (
                <th key={header} className={listOfClasses[header]}>{header}
                  {<span onClick={() => handleEtaSort(headerLowerCase)}>
                    <UpDown color={isSortModalOpen[headerLowerCase]?.value ? "#2060ea" : "#676879"} />
                  </span>}

                  {(isSortModalOpen[headerLowerCase]?.isOpen) &&
                    <SelectSortOption
                      valueOfColumn={headerLowerCase}
                      closeModal={() => closeSelectSortOption(headerLowerCase)}
                      sortOptions={(dateFields.includes(headerLowerCase)) ? dataForSort['eta'] : dataForSort['status']}
                      selectedOption={isSortModalOpen[headerLowerCase].value}
                      setIsSortModalOpen={setIsSortModalOpen}
                      handleSortByEta={handleSort[dateFields.includes(headerLowerCase) ? 'eta' : 'status']} />}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody onClick={handleSelectOrder}>
          {tableHeaderList && selectedOrder.map((order, ind, arr) => {
            return (
              <tr key={order.id}>
                <td className='checkBox'><input type="checkbox" onChange={demoFn} value={order.id} checked={selectedOrderFromList.includes(String(order.id))} /></td>
                {orderType === "shipment" && <td className='projectName'><Link to={`${order.id}`}> {order.name}</Link></td>}
                {orderType === "purchase" && <td className='projectName'><Link to={`detail/${order.id}`}> {order.name}</Link></td>}
                {orderType === "transfer" && <td className='projectName'><Link to={`${order.id}`}> {order.name}</Link></td>}
                {orderType === "inventory" && <td className='projectName'><Link to={`${order.id}`}> {order.product.number}</Link></td>}

                {tableDataElements(order)}
              </tr>
            )
          }
          )}

          {orderType === "allocation" && <tr className={`total moreItems`}>
            <td className="checkBox"></td>
            {
              tableHeaderList.map(item => {
                if (item === "REQUIRED DATE") {
                  return <td key={item} className="dispatched totalText"> Total</td>;
                }
                return <td key={item} className={listOfClasses[item]} >{(totalCount[countMapWithHeader[item]] === 0 || totalCount[countMapWithHeader[item]]) && totalCount[countMapWithHeader[item]].toFixed(2)} </td>
              })
            }
          </tr>}
        </tbody>
      </table>
    </ListViewCss>
  )
}

export const ListViewCss = styled.div` 
  overflow-x: auto;
  flex: 1;

  table,
  thead,
  tbody {
    width: 100%;
    font-size: 14px;
  }
  > table {
    min-width: max-content;
    height: 100%;

    > thead,
    tbody > tr {
      .stockValue {
        width: 10vw;
      }
      .sellable {
        width: 9vw;
      }
      .category {
        width: 8vw;
      }
      .dispatched{
        width: 11vw;
      }
      .allocatedVal{
        width: 12vw;
      }
    }
    > thead > tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      margin-right: 12px;


      > th {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 10px;
        color: #4a4a68;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        position: relative; 
        
        svg {
          cursor: pointer;
        }
      }
      > .checkBox {
        padding: 8px 10px 8px 20px;

        > input {
          width: 16px;
          height: 16px;
        }
      }
      .seriesName {
        width: 48px;
      }
      .projectName {
        padding: 8px 10px 8px 22px;
        min-width: 200px;
        position: relative;

        > .closeFilter {
          display: flex;
          padding: 0px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 100px;
          background: var(--Peach, #f3d9da);
          cursor: pointer;
        }
      }
      .statusHeader {
        width: 11vw;
      }
      .estimatedDate {
        width: 11rem;
      }
      .userPic {
        width: 144px;
      }
      .wareHouse {
        width: 12vw;
      }
      .supplier {
        width: 15vw;
      }
    }
    > tbody {
      overflow-y: auto;
      height: 100%;
      scrollbar-width: thin;
      display: block;
      > tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        > td {
          padding: 12px 10px;
        }

        > .checkBox {
          display: flex;
          padding: 8px 10px 8px 20px;
          width: 46px;

          > input {
            width: 16px;
            height: 16px;
          }
        }

        .projectName {
          min-width: 200px;
          padding-left: 22px;
          color: #1249bf;
          font-weight: 500;
          cursor: pointer;
          a {
            color: #1249bf;
          }
        }

        .seriesName {
          width: 48px;
        }
      .statusHeader {
        width: 11vw;
      }
        .status {
          width: 11vw;
          font-size: 12px;
          line-height: 18px;

          span {
            display: flex;
            align-items: center;
            gap: 6px;
            background-color: #e1fcef;
            padding: 0 8px;
            width: max-content;

            .statusBox {
              width: 6px;
              height: 6px;
              background-color: #38a06c;
              border-radius: 2px;
            }
          }
        }
        .estimatedDate {
          width: 11rem;
          font-weight: 400;
          display: flex;
          align-items: center;
          gap: 6px;
        }
        .userPic {
          width: 144px;
          font-weight: 400;
        }

        .wareHouse {
          font-weight: 400;
          width: 12vw;
        }
        .supplier {
          width: 15vw;
        }
      }
      .total{
        position: sticky;
        bottom: 0; 
        font-size: 14px;
        border-top: 1px solid var(--Color-Tokens-Border-Primary, #E4E4E4);
        background-color: var(--CLOUD, #FCFDFE);
        font-weight: 500;
        height: 40px;
        >.totalText{
          font-weight: 600;
          text-align: center;

        }
      }
    }
  }

`