import { useRef, useState } from "react";
import { InputText } from "../../../components/InputText/InputText";
import styled from "styled-components";
import { useAddTrackingInfoMutation, useUpdateTrackingDetailsMutation } from "../../../../../store/apiSlice/newInterfaceApiSlice";
import { format } from "date-fns";

export const TrackingInfo = ({ orderId, trackInfoFromParent, orderType }) => {
  const [trackInfo, setTrackInfo] = useState(trackInfoFromParent);
  const [editMode, setEditMode] = useState(false);
  const [createTrackInfo] = useAddTrackingInfoMutation();
  const [updateTrackInfo] = useUpdateTrackingDetailsMutation();
  const formRef = useRef();

  const handleInputChange = (e) => {
    setTrackInfo({ ...trackInfo, [e.target.name]: e.target.value })
  }

  const handleCreate = (e) => {
    setTrackInfo({
      companyName: '',
      ID: '',
      trackingLink: '',
      meta: {},
      last_modified: Date.now()
    })
    setEditMode(true)
  }

  const toggleView = async (e) => {
    if (e.target.textContent === 'Edit') {
      setEditMode(true)
    }
    else if (e.target.textContent === 'Save') {
      if (!formRef.current.checkValidity() || !/^(https?:\/\/)?([\w\d-]+\.)+[\w]{2,}(\/.+)*$/.test(trackInfo.url)) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      if (trackInfoFromParent === null || trackInfoFromParent === undefined) {
        await createTrackInfo({ ...trackInfo, orderId, orderType })
        setEditMode(false);
        return;
      }
      updateTrackInfo({ id: trackInfo.id, data: trackInfo, orderType });
      setEditMode(false);
    }
  }

  return (
    <TrackInfoContainer className={`trackingInfo`}>
      {
        trackInfo ? <>
          {editMode ?
            <form ref={formRef} className="editView">
              <InputText
                labelOfInput='Logistics Partner: '
                inputName="logistics_partner" placeholder="Enter logistics partner name"
                text={trackInfo?.logistics_partner}
                setText={handleInputChange}
              />
              <InputText
                labelOfInput='Tracking Link: '
                inputName="url" placeholder="Enter tracking link here"
                type='url'
                text={trackInfo?.url}
                setText={handleInputChange}
              />

              <InputText
                labelOfInput='ID: '
                inputName="reference" placeholder="Enter ID"
                text={trackInfo?.reference}
                setText={handleInputChange}
              />

              <button className="createBtn" disabled={!trackInfo.url} onClick={toggleView}>Save</button>
            </form>
            :
            <div className="dataView">
              <p>
                <span className="leftInfo">Logistics Partner: </span>
                <span className="value">{trackInfo?.logistics_partner}</span>
              </p>
              <a target="_blank" rel="noreferrer" href={trackInfo?.url ? trackInfo.url : '/'} className="trackingLink" to={trackInfo?.url ? trackInfo.url : '/'} > Tracking link </a>
              <p><span className="leftInfo">ID: </span>
                <span className="value">{trackInfo?.reference}</span>
              </p>
              <p>
                <span >{trackInfo?.updated_by ? 'Updated by' : 'Created by'} {trackInfo.updated_by ? trackInfo.updated_by.full_name : trackInfo.created_by?.full_name} </span>
                <span > {format(trackInfo?.last_modified, 'dd/MM/yyyy, hh:mm a')}</span>

              </p>
              <button className="createBtn" disabled={!trackInfo.url} onClick={toggleView}>Edit</button>
            </div>
          }

        </>
          : <>
            <p className="createInfo">Your track journey starts here! Click below to create it! </p>
            <button className="createBtn" onClick={handleCreate}>Create</button>
          </>
      }
    </TrackInfoContainer>
  )
}

const TrackInfoContainer = styled.div` 
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  position: relative;
  font-weight: 400;

  .createInfo{
    color: var(--DARK-GREY, #0E0E2C);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  h1{
    margin-bottom: 0;
  }
  >.editView{
    display: flex;
    flex-direction: column;
    gap: 10px;

    input{
        height: 24px;
        width: 100%;
    }

    .addMoreField{
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

  }
  >.dataView{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--GREY, #4A4A68);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    
    >p{
      display: flex;
      flex-direction: column;
      .leftInfo{
          display: inline-block;
          width: 8rem;
          
        }
        .value{
          color: var(--DARK-GREY, #0E0E2C);
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
      }
      .trackingLink{
        color: #2060ea; 
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.28px;
      }
  }

  .createBtn { 
    background-color: #0095e8;
    color: white;
    padding: 5px 15px;
    border: 1px;
    border-radius: 4px;
    width: max-content;
    font-size: 14px;
  } 
  .forAddMore{
    border: 1px;
    padding: 4px 16px;
    border-radius: 4px;
    width: max-content; 
  }


`