import {
  addConsignmentFiles,
  addConsignmentNote,
  addShipment,
  editConsignment,
  editConsignmentItem,
  setASNList,
  setCancelConsignments,
  setConsignment,
  setConsignmentBills,
  setConsignmentFiles,
  setConsignmentItems,
  setConsignmentItemsStatus,
  setConsignmentNotes,
  setConsignments,
  setConsignmentsSelection,
  setShipment,
  setShipments,
  updateConsignment,
  updateConsignmentForASNValidation,
  updateShipment,
  updateShipmentLine,
  updateTrackingInfo,
} from "../slice/shipmentSlice";
import { apiSlice } from "./apiSlice";
import { setNotification } from "../slice/miscSlice";

export const shipmentApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    approveShipment: builder.mutation({
      query: ({ shipmentId }) => ({
        url: `/api_shipment/shipment/${shipmentId}/approval/`,
        method: "PATCH",
        body: {},
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateShipment(result.data)))
          .catch(error => {
            setNotification({
              type: "error",
              message: error.data?.detail || "Something went wrong",
            });
          });
      },
    }),
    cancelConsignments: builder.mutation({
      query: data => ({
        url: "/api_shipment/consignment/bulk_cancel/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => dispatch(setCancelConsignments(data))).catch(error => { });
      },
    }),
    changeConsignment: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_shipment/consignment/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    confirmASN: builder.mutation({
      query: ({ id }) => ({
        url: `/api_shipment/asn/${id}/confirm/`,
        method: "PATCH",
        body: {},
      }),
    }),
    createASN: builder.mutation({
      query: data => ({ url: "/api_shipment/asn/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateConsignment(result.data))).catch(error => { });
      },
    }),
    createConsignmentBill: builder.mutation({
      query: data => ({
        url: `/api_invoice/bill/create_from_consignment/`,
        method: "POST",
        body: data,
      }),
    }),
    createConsignmentFiles: builder.mutation({
      query: data => ({
        url: `/api_docdigit/document/create_consignment_files/`,
        method: "POST",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(addConsignmentFiles(result.data)))
          .catch(error => { });
      },
    }),
    createConsignmentNote: builder.mutation({
      query: data => ({
        url: "/api_chat/note/",
        method: "POST",
        body: data,
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled, getState }) => {
        queryFulfilled.then(result => {
          if (!data.is_view_rule_exists && getState().shipment.consignment?.notes?.length === 0) {
            dispatch(shipmentApiSlice.util.invalidateTags(["consignmentList"]))
          }
          dispatch(addConsignmentNote(result.data));
        }).catch(error => { });
      },
    }),
    createShipment: builder.mutation({
      query: data => ({ url: "/api_shipment/shipment/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(addShipment(result.data))).catch(error => { });
      },
    }),
    editShipment: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_shipment/shipment/${id}/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateShipment(result.data))).catch(error => { });
      },
    }),
    editShipmentLine: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_shipment/shipment_line/${id}/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateShipmentLine(result.data))).catch(error => { });
      },
    }),
    getASNList: builder.query({
      query: params => (params ? `/api_shipment/asn/?${params}` : "/api_shipment/asn/"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setASNList(result.data))).catch(error => { });
      },
    }),
    getConsignment: builder.query({
      query: id => `/api_shipment/consignment/${id}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setConsignment(result.data))).catch(error => { });
      },
    }),
    getConsignmentForUpdate: builder.query({
      query: id => `/api_shipment/consignment/${id}/?mode=update`,
      providesTags: _ => ["consignmentUpdateInfo"],
    }),
    getConsignmentNotes: builder.query({
      query: params => (params ? `/api_chat/note/?${params}` : "/api_chat/note/"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setConsignmentNotes(result.data)))
          .catch(error => { });
      },
    }),
    getConsignmentItems: builder.query({
      query: params =>
        params ? `/api_shipment/consignment_item/?${params}` : "/api_shipment/consignment_item/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setConsignmentItems(result.data)))
          .catch(error => { });
      },
    }),
    getConsignmentItemsStatus: builder.query({
      query: () => "/api_shipment/consignment_item_status/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setConsignmentItemsStatus(result.data)))
          .catch(error => { });
      },
    }),
    getConsignments: builder.query({
      query: params =>
        params ? `/api_shipment/consignment/?${params}` : "/api_shipment/consignment/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setConsignments(result.data))).catch(error => { });
      },
      providesTags: _ => ["consignmentList"],
    }),
    getConsignmentsSelection: builder.query({
      query: params =>
        params
          ? `/api_shipment/consignment/?mode=selection&${params}`
          : "/api_shipment/consignment/?mode=selection",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setConsignmentsSelection(result.data)))
          .catch(error => { });
      },
    }),
    getDownloadedConsignmentItems: builder.query({
      query: params =>
        params
          ? `/api_shipment/consignment_item/download_records/?${params}`
          : "/api_shipment/consignment_item/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => { }).catch(error => { });
      },
    }),
    getBillsForConsignment: builder.query({
      query: params => `/api_invoice/bill/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setConsignmentBills(result.data)))
          .catch(error => { });
      },
    }),
    getFilesForConsignment: builder.query({
      query: params => `/api_docdigit/document/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setConsignmentFiles(result.data)))
          .catch(error => { });
      },
    }),
    getShipment: builder.query({
      query: ({ id, params = null }) =>
        params ? `/api_shipment/shipment/${id}/?${params}` : `/api_shipment/shipment/${id}/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setShipment(result.data))).catch(error => { });
      },
    }),
    getShipments: builder.query({
      query: (params = null) => `/api_shipment/shipment/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setShipments(result.data))).catch(error => { });
      },
    }),
    syncASN: builder.mutation({
      query: () => ({ url: "/api_shipment/sync_asn/", method: "post", body: {} }),
    }),
    updateConsignmentItemETA: builder.mutation({
      query: data => ({
        url: `/api_shipment/consignment_item/update_eta/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(editConsignmentItem(result.data)))
          .catch(error => { });
      },
    }),
    updateConsignmentDefaultUOMPack: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_shipment/consignment/${id}/update_default_pack_uom/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(editConsignment(result.data))).catch(error => { });
      },
    }),
    updateConsignmentReference: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_shipment/consignment/${id}/update_reference/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(editConsignment(result.data))).catch(error => { });
      },
    }),
    updateInternationalConsignment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_shipment/consignment/${id}/update_consignment_shipment/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(editConsignment(result.data.data)))
          .catch(error => { });
      },
    }),
    updateTrackingInfo: builder.mutation({
      query: data => ({ url: "/api_shipment/mutate_container/", method: "POST", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateTrackingInfo(result.data))).catch(error => { });
      },
    }),
    validateConsignmentForASN: builder.mutation({
      query: ({ id }) => ({
        url: `/api_shipment/consignment/${id}/validate_for_asn/`,
        method: "PATCH",
        body: {},
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateConsignmentForASNValidation(result.data)))
          .catch(error => { });
      },
    }),
  }),
});
export const {
  useApproveShipmentMutation,
  useCancelConsignmentsMutation,
  useChangeConsignmentMutation,
  useConfirmASNMutation,
  useCreateASNMutation,
  useCreateConsignmentBillMutation,
  useCreateConsignmentFilesMutation,
  useCreateConsignmentNoteMutation,
  useCreateShipmentMutation,
  useEditShipmentLineMutation,
  useEditShipmentMutation,
  useGetASNListQuery,
  useGetBillsForConsignmentQuery,
  useGetConsignmentForUpdateQuery,
  useGetConsignmentItemsQuery,
  useGetConsignmentItemsStatusQuery,
  useGetConsignmentNotesQuery,
  useGetConsignmentQuery,
  useGetConsignmentsQuery,
  useGetConsignmentsSelectionQuery,
  useGetFilesForConsignmentQuery,
  useGetShipmentQuery,
  useGetShipmentsQuery,
  useLazyGetDownloadedConsignmentItemsQuery,
  useSyncASNMutation,
  useUpdateConsignmentDefaultUOMPackMutation,
  useUpdateConsignmentItemETAMutation,
  useUpdateConsignmentReferenceMutation,
  useUpdateInternationalConsignmentMutation,
  useUpdateTrackingInfoMutation,
  useValidateConsignmentForASNMutation,
} = shipmentApiSlice;
