import { ReactComponent as TrackIcon } from '../../../assets/svgIcon/shipment/track.svg'
import { ReactComponent as NotesIcon } from '../../../assets/svgIcon/shipment/Notes.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/svgIcon/detailsPage/Download.svg'
import { ReactComponent as HistoryIcon } from '../../../assets/svgIcon/detailsPage/History.svg'
export const NavigationInDetails = ({ orderType }) => {

    return (
        <nav className='navigationOfDetails'>
            <div className="leftSide">
                <div className="left">
                    <div className="navItem activeItem">Details</div>
                    {orderType !== "shipment" && <div className="navItem">Shipment</div>}
                    <div className="navItem">Invoice</div>
                </div>
                <div className="navItem leftMargin">Files</div>
            </div>

            <div className="rightSide btnGroup">
                <span className='iconWrapper' title='Notes'><NotesIcon /></span>
                <span className='iconWrapper' title='Tracking info'><TrackIcon /></span>
                <span className='iconWrapper' title='Download'><DownloadIcon /> </span>
                <span className='iconWrapper' title='Activity logs'><HistoryIcon /> </span>
            </div>
        </nav>
    )
}
