import React from 'react';
import { ReactComponent as Clock } from '../../../assets/svgIcon/listView/timer.svg'
import { showDateAndTime } from "../../utils/showDateAndTime";
import { Button } from '../Button/Button';

export const tableHeaderList = {
    purchase: ["UOM", "ETA", "STATUS", "ORDERED QTY", "Dispatched QTY", "Receipted QTY", "Billed QTY", "Unit Price", "Sub Total"],
    transfer: ["UOM", "ETA", "STATUS", "ORDERED QTY", "Dispatched QTY", "Receipted QTY", "ALLOCATED QTY", "Unit Price", "TOTAL COST"],
    shipment: ["ORDER NO", "SUPPLIER", "UOM", "CONTAINER", "ETA", "ATA", "ORDERED QTY", "Dispatched QTY", "Receipted QTY", "NO OF PACKS"],

}
export const tableHeaderListIncludeTF = ["UOM", "ETA", "STATUS", "TF ORD", "TF TO WAREHOUSE", "ORDERED QTY", "Dispatched QTY", "Receipted QTY", "Billed QTY", "Unit Price", "Sub Total"];

export const listOfClasses = {
    "UOM": "uom", "ETA": "estimatedDate", "STATUS": "statusHeader", "ORDERED QTY": "quantity", "Dispatched QTY": "dispatched", "Receipted QTY": "quantity",
    "ALLOCATED QTY": "dispatched", "Billed QTY": "smallWidth", "Received QTY": "quantity", "ORDER NO": "quantity orderNo", "SUPPLIER": "tfToWarehouse supplier", "ATA": "ata",
    "Unit Price": "smallWidth", "Sub Total": "smallWidth subTotal", "TF TO WAREHOUSE": "tfToWarehouse", "TF ORD": "totalCost tfOrd", "TOTAL COST": "totalCost", "CONTAINER": "quantity containerForShipment",
    "NO OF PACKS": "totalCost"

}

export const tableData = (order, displayTime, orderType, openTrackingStatusModal) => {
    const [time = null, etaWithTime = null] = showDateAndTime(order?.eta, displayTime);
    const orderedQty = orderType === "shipment" ? order.line.ordered_qty : order.ordered_qty;
    const uom = orderType === "shipment" ? order.pack_uom : order.uom;
    return {
        "ORDER NO": <td key="ORD NO" className="quantity">{order.line ? order.line.order_no : '--'}</td>,
        "UOM": <td key="uom" className="uom">{uom ? uom : '--'}</td>,
        "SUPPLIER": <td key="SUPPlier" className="tfToWarehouse">{order.supplier?.name}</td>,
        "ATA": <td key="ata" className="ata">{order.ata ? order.ata : '--'}</td>,
        "NO OF PACKS": <td key="packs" className="totalCost">{Number(order.pack_qty).toFixed(2)}</td>,
        "ETA": <td key="eta" className="estimatedDate" title={etaWithTime}> {time ? <> <Clock /> {time}</> : '--'}</td>,
        "ORDERED QTY": <td className="quantity" key="order">{Number(orderedQty).toFixed(2)}</td>,
        "Dispatched QTY": <td className="dispatched" key="dis">{Number(order.dispatched_qty).toFixed(2)}</td>,
        "Receipted QTY": <td className="quantity" key="rec">{Number(order.received_qty).toFixed(2)}</td>,
        "Received QTY": <td className="quantity" key="recid">{Number(order.received_qty).toFixed(2)}</td>,
        "ALLOCATED QTY": order.allocated_qty && <td className="dispatched" key="alloc">{Number(order.allocated_qty).toFixed(2)}</td>,
        "Billed QTY": <td className="smallWidth" key="bil">{Number(order.invoiced_qty).toFixed(2)}</td>,
        "Unit Price": <td className="smallWidth" key="uni">{order.currency?.symbol} {Number(order.unit_price).toFixed(2)}</td>,
        "Sub Total": <td className="smallWidth" key="sub">{Number(order.sub_total).toFixed(2)}</td>,
        "TOTAL COST": order.total_cost && <td className="totalCost" key="total">{Number(order.total_cost)}</td>,
        "TF ORD": <td className="totalCost" key="tf ord">{order.tf_ord ? order.tf_ord.name : <span className="dataNotPresent">Not Available</span>}</td>,
        "TF TO WAREHOUSE": <td className="tfToWarehouse" key="tf warehouse">{order.tf_ord ? order.tf_ord_to_warehouse : <span className="dataNotPresent">Not Available</span>}</td>,
        "CONTAINER": <td key='container' className='quantity containerForShipment'>{order.container?.container_no ? <Button btnName={order.container.container_no} classOfBtn="container " handler={() => openTrackingStatusModal(order.container.container_no)} /> : '--'}  </td>,
        "STATUS": <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
    }
}

export const rowDataOfTable = (order, displayTime, orderType, headerList, openTrackingStatusModal) => {
    const allElements = tableData(order, displayTime, orderType, openTrackingStatusModal);
    return headerList.map(item => allElements[item]);
}

export const countMapWithHeader = {
    "ORDERED QTY": "ordered",
    "Dispatched QTY": "dispatched",
    "Receipted QTY": "reciepted",
    "Billed QTY": "billed",
    "Sub Total": "subTotal",
    "ALLOCATED QTY": "alloacted",
    "TOTAL COST": "totalCost",
    "NO OF PACKS": "totalPack",
}
export const totalCountForData = {
    purchase: (po_line) => {
        let totalCount = {
            ordered: 0,
            dispatched: 0,
            reciepted: 0,
            billed: 0,
            subTotal: 0,
        }
        po_line.forEach(item => {
            totalCount = {
                ordered: totalCount.ordered + Number(item.ordered_qty),
                dispatched: totalCount.dispatched + Number(item.dispatched_qty),
                reciepted: totalCount.reciepted + Number(item.received_qty),
                billed: totalCount.billed + Number(item.invoiced_qty),
                subTotal: totalCount.subTotal + Number(item.sub_total),
            }
        })

        return totalCount;
    },
    transfer: (po_line) => {
        let totalCount = {
            ordered: 0,
            dispatched: 0,
            reciepted: 0,
            alloacted: 0,
            totalCost: 0,
        }
        po_line.forEach(item => {
            totalCount = {
                ordered: totalCount.ordered + Number(item.ordered_qty),
                dispatched: totalCount.dispatched + Number(item.dispatched_qty),
                reciepted: totalCount.reciepted + Number(item.received_qty),
                alloacted: totalCount.alloacted + Number(item.allocated_qty),
                totalCost: totalCount.totalCost + Number(item.total_cost),
            }
        })

        return totalCount;
    },
    shipment: (po_line) => {
        let totalCount = {
            ordered: 0,
            dispatched: 0,
            reciepted: 0,
            totalPack: 0
        };

        po_line.forEach(item => {
            totalCount = {
                ordered: totalCount.ordered + Number(item.line.ordered_qty),
                dispatched: totalCount.dispatched + Number(item.dispatched_qty),
                reciepted: totalCount.reciepted + Number(item.received_qty),
                totalPack: totalCount.totalPack + Number(item.pack_qty),
            }
        })

        return totalCount;
    }
}

export const allOrderName = {
    shipment: (po_line) => {

        const allColumnNames = {
            orderNumber: [],
            supplier: [],
            product: [],
        }
        po_line.forEach(order => {
            if (!allColumnNames.orderNumber.includes(order.line?.order_no)) allColumnNames.orderNumber.push(order.line?.order_no);
            if (!allColumnNames.supplier.includes(order.supplier?.name)) allColumnNames.supplier.push(order.supplier?.name);
            allColumnNames.product?.push(order.line?.product);
        })

        return allColumnNames;
    },
    purchase: (po_line) => {

        const allColumnNames = {
            product: [],
        }
        po_line.forEach(order => {
            allColumnNames.product?.push(order.product?.number);
        })

        return allColumnNames;
    },
    transfer: (po_line) => {
        const allColumnNames = {
            product: [],
        }
        po_line.forEach(order => {
            allColumnNames.product?.push(order.product?.number);
        })

        return allColumnNames;
    },

}

export const checkZeroOrNot = {
    "Received Qty": (name, order) => {
        if (name === 'Zero') return Number(order?.received_qty) === 0;
        if (name === 'Non zero') return Number(order?.received_qty) !== 0;
    },
    "Order Qty": (name, order) => {
        if (name === 'Zero') return Number(order.line ? order?.line.ordered_qty : order.ordered_qty) === 0;
        if (name === 'Non zero') return Number(order.line ? order?.line.ordered_qty : order.ordered_qty) !== 0;
    },
    "Dispatched Qty": (name, order) => {
        if (name === 'Zero') return Number(order?.dispatched_qty) === 0;
        if (name === 'Non zero') return Number(order?.dispatched_qty) !== 0;
    },
    "Allocated Qty": (name, order) => {
        if (name === 'Zero') return Number(order?.allocated_qty) === 0;
        if (name === 'Non zero') return Number(order?.allocated_qty) !== 0;
    }
}

