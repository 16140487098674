import styled from 'styled-components'
import { ReactComponent as EditIcon } from '../../../assets/svgIcon/detailsPage/editIcon.svg'


export const ButtonForTopBar = ({ isExistIcon = true, btnName = "Edit Details", handleClick }) => {
    return (
        <Button onClick={handleClick}>
            {isExistIcon && <EditIcon />}
            {btnName}
        </Button>
    )
}

const Button = styled.div`
    display: flex;
    height: 32px;
    padding: 4px 15px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background-color: var(--MIST, #F7F8F9);
    color: var(--BLUE, #2060EA);
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    `