import { useEffect, useState } from 'react'
import { Down } from '../../../assets/svgIcon/listView/Down'
import { ReactComponent as UpIcon } from '../../../assets/svgIcon/listView/UpBlue.svg'
import { ReactComponent as Check } from '../../../assets/svgIcon/listView/check.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/svgIcon/shipment/DeleteOutlined.svg'
import { PopupModal } from '../../../components/TopBar/PopupModal/PopupModal';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteViewMutation } from '../../../../../store/apiSlice/newInterfaceApiSlice'
import { DeleteModal } from '../../../components/DeleteModal/DeleteModal'
import { dataForSelectedState, moduleForView, selectState, selectStateForOrder } from '../../utils/tableDataElements'
import { setCurrentViewInLocals } from '../../../../../store/slice/newInterfaceSlice'

export function SelectComponent({ title, boardView, setBoardView, data, LeftIcon, setisOpenCreateView, setRowsPerPage, selectedCurrentView, setSelectedCurrentView, setEditView, orderType, allocation }) {

  const { count: totalOrderCount } = useSelector(state => state[selectStateForOrder[orderType]][dataForSelectedState[orderType]]);
  const { filteredConsignmentByRule } = useSelector(state => state.newInterface);
  const { boardView: { status: { nameOfViews: listOfViewForStatus }, milestone: { nameOfViews: listOfViewForMilestone } }, listView: { nameOfViews: listOfViewsForTable } } = useSelector(state => {
    if (orderType === "allocation") {
      return state['newTransferOrder']['allocation'][allocation];
    }
    return state[selectState[orderType]].allViews;
  });
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null);
  const [deleteView] = useDeleteViewMutation();
  const dispatch = useDispatch();

  const closeModal = () => setIsOpenSelect(false)
  const openModal = () => setIsOpenSelect(true)

  const closeDeleteModal = () => setIsOpenDeleteModal(false)
  const openDeleteModal = (e, id, name) => {
    e.stopPropagation();
    setIsOpenDeleteModal(true)
    setDeleteData({ id, name })
  }

  const handleDeleteModal = () => {
    deleteView(deleteData.id)
    closeDeleteModal();
  }

  const handleSelect = (value, id, is_view_rule_exists) => {
    if (value === 'All') {
      if (selectedCurrentView.value === "Default View" || !selectedCurrentView.is_view_rule_exists) {
        setRowsPerPage(totalOrderCount);
      } else {
        setRowsPerPage(filteredConsignmentByRule?.length);
      }
      closeModal()
      return
    }
    if (value.slice(-4) === 'rows') {
      setRowsPerPage(value.split('rows')[0])
      closeModal()
      return;
    }

    const selectedView = { value: value, sub_type: boardView ? 'status' : null, id: id, is_view_rule_exists };
    dispatch(setCurrentViewInLocals({ view: selectedView, module: moduleForView[orderType] }))
    setSelectedCurrentView(selectedView);
    setSelectedValue(value)
    closeModal()
  }

  const handleSelectForStatus = (e, value, id, is_view_rule_exists) => {
    e.stopPropagation();

    const selectedView = { value: value, sub_type: 'status', id: id, is_view_rule_exists };
    dispatch(setCurrentViewInLocals({ view: selectedView, module: moduleForView[orderType] }))
    setSelectedCurrentView(selectedView);
    setBoardView(true)
    setSelectedValue(value);
    closeModal()
  }

  const handleSelectForMilestone = (e, value, id, is_view_rule_exists) => {
    e.stopPropagation();
    const selectedView = { value: value, sub_type: 'milestone', id: id, is_view_rule_exists };
    dispatch(setCurrentViewInLocals({ view: selectedView, module: moduleForView[orderType] }))
    setSelectedCurrentView(selectedView);
    setBoardView(true)
    setSelectedValue(value)
    closeModal()
  }

  const openCreateView = (e) => {
    e.stopPropagation();
    setisOpenCreateView(true);
    setIsOpenSelect(false)
  }

  const editView = (e) => {
    e.stopPropagation();
    setisOpenCreateView(true);
    setIsOpenSelect(false);
    setEditView(true)
  }

  useEffect(() => {
    if (selectedCurrentView?.value) {
      setSelectedValue(selectedCurrentView.value);
    }
  }, [selectedCurrentView])

  return (

    <div className='selectBox' >
      <div className={isOpenSelect ? 'activeSelectTitle selectTitle' : "selectTitle"} onClick={openModal} >
        {!data?.length > 0 && <LeftIcon color={isOpenSelect ? '#2060EA' : '#4A4A68'} />}
        {title}
        {isOpenSelect ? <UpIcon /> : <Down />}
      </div>

      {isOpenDeleteModal && <DeleteModal
        handleDeleteModal={handleDeleteModal}
        closeDeleteModal={closeDeleteModal}
        nameOfView={`Think carefully! Deleting this "${deleteData.name}" view is permanent, and the data will be lost forever.`}
        labelForConfirmCheck={`I understand that the data cannot be retrieved after deleting.`}
      />}

      {isOpenSelect &&
        <PopupModal styleOfModal={styleOfModal} closeModal={closeModal}>
          <div className="optionsOfSelect">
            {!data?.length > 0 && <div className="optionItem d-flex" onClick={() => handleSelect('Default View')}>
              Default View
              {selectedValue === 'Default View' && <Check />}
            </div>}

            {data?.length > 0 ? data.map(item => (
              <div key={item} className="optionItem d-flex " onClick={() => handleSelect(item)}>
                {item}
                {selectedValue === item && <Check />}
              </div>
            )) :

              boardView ?
                (listOfViewForStatus?.length > 0 || listOfViewForMilestone?.length > 0) &&
                <>
                  {listOfViewForStatus.length > 0 && <div className="listOfView">
                    <p >Status</p>
                    {listOfViewForStatus.map(item =>
                      <div key={item.name} className="optionItem d-flex" onClick={(e) => handleSelectForStatus(e, item.name, item.id, item.is_view_rule_exists)}>
                        {item.name}
                        <div className="btnGroup">
                          {selectedValue === item.name && <Check />}
                          <span className="iconWrapper" onClick={(e) => openDeleteModal(e, item.id, item.name)}> <DeleteIcon /> </span>
                        </div>
                      </div>
                    )}
                  </div>
                  }

                  {listOfViewForMilestone.length > 0 && <div className="listOfView">
                    <p>Milestone</p>
                    {listOfViewForMilestone.map(item =>
                      <div key={item.name} className="optionItem d-flex" onClick={e => handleSelectForMilestone(e, item.name, item.id, item.is_view_rule_exists)}>
                        {item.name}

                        <div className="btnGroup">
                          {selectedValue === item.name && <Check />}
                          <span className="iconWrapper" onClick={(e) => openDeleteModal(e, item.id, item.name)} > <DeleteIcon /> </span>
                        </div>
                      </div>
                    )}
                  </div>
                  }
                </>
                : listOfViewsForTable?.length > 0 && listOfViewsForTable.map(item =>
                  <div key={item.name} className="optionItem d-flex" onClick={() => handleSelect(item.name, item.id, item.is_view_rule_exists)}>
                    {item.name}

                    <div className="btnGroup">
                      {selectedValue === item.name && <Check />}
                      <span className="iconWrapper" onClick={(e) => openDeleteModal(e, item.id, item.name)}> <DeleteIcon /> </span>
                    </div>
                  </div>
                )
            }

            {!data?.length > 0 && (listOfViewForMilestone?.length > 0 || listOfViewForStatus?.length > 0 || listOfViewsForTable?.length > 0) &&
              <>
                <div className="optionItem d-flex editView" onClick={editView}>
                  Edit Current View
                </div>
              </>
            }{
              !data?.length > 0 &&
              <div className="optionItem d-flex editView" onClick={openCreateView}>
                Create View
              </div>
            }
          </div>
        </PopupModal>
      }
    </div>
  )
}

export const styleOfModal = { position: 'absolute', zIndex: 5 }

export const styleOfDeleteModal = {
  position: 'absolute', zIndex: 5,
  margin: 'auto',
  left: 0,
  backgroundColor: 'white',
  right: 0,
  maxWidth: 'max-content',
}

