import { ListIcon } from '../../../assets/svgIcon/listView/ListIcon'
import { BoardIcon } from '../../../assets/svgIcon/listView/Board'
import { Eye } from '../../../assets/svgIcon/listView/Eye'
import { ReactComponent as MenuIcon } from '../../../assets/svgIcon/shipment/Menu.svg'
import { ReactComponent as UpIcon } from '../../../assets/svgIcon/listView/UpBlue.svg'
import { FilterIcon } from '../../../assets/svgIcon/listView/FilterIcon.jsx'
import { Down } from '../../../assets/svgIcon/listView/Down'
import { SelectComponent } from '../SelectComponent/SelectComponent.jsx'
import { ViewRules } from '../ViewRules/ViewRules.jsx'
import { BlurModal } from '../BlurModal/BlurModal.jsx'
import { Button } from '../Button/Button.jsx'
import { useState } from 'react'
import { Milestone } from '../../../components/Milestone/Milestone.jsx'
import { GeneralModal } from '../../../components/GeneralModal/GeneralModal.jsx'
import { useCreateFilterCriteriaMutation, useDeleteFilterCriteriaMutation, useUpdateFilterCriteriaMutation } from '../../../../../store/apiSlice/newInterfaceApiSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../../../store/slice/miscSlice.js'
import { selectState } from '../../utils/tableDataElements.js'
import { SelectAllocation } from '../../Allocation/SelectAllocation.jsx'
import { useCreateShipmentFromPurchaseAllocationMutation, useCreateShipmentFromTransferAllocationMutation } from '../../../../../store/apiSlice/orderApiSlice.js'
import { useNotification } from '../../../../../hooks/useNotification.jsx'
import { ListMenuOptions } from './ListMenuOptions.jsx'

export function OptionsForView({ boardView, setBoardView, selectedOrderLength, titleForRows, setRowsPerPage, setisOpenCreateView, selectedCurrentView, setSelectedCurrentView, setEditView,
    getConsignmentByRule, orderType, setAllocation, allocation, selectedOrderFromListview, setSelectedOrder, currentViewDetails }) {
    const rowsPerPage = ['All', '10 rows', '20 rows', '40 rows', '60 rows', '100 rows'];
    const [isOpenMilestone, setIsOpenMilestone] = useState(false);
    const [isOpenAllocation, setIsOpenAllocation] = useState(false);
    const [isOpenViewRules, setIsOpenViewRules] = useState(false);
    const [isOpenListMenu, setIsOpenListMenu] = useState(false)
    const [editPattern, setEditPattern] = useState(1);
    const [createViewRule] = useCreateFilterCriteriaMutation();
    const [updateViewRule] = useUpdateFilterCriteriaMutation();
    const [deleteViewRule] = useDeleteFilterCriteriaMutation();
    const [filterCriteriaForCurrentView, setFilterCriteriaForCurrentView] = useState(null);
    const { completeListOfViews } = useSelector(state => state[selectState[orderType]].allViews);
    const dispatch = useDispatch();
    const notify = useNotification();

    const [createShipmentFromPurchaseAllocation, { isLoading }] =
        useCreateShipmentFromPurchaseAllocationMutation();
    const [createShipmentFromTransferAllocation, { isLoading: isLoadingForTransfer }] =
        useCreateShipmentFromTransferAllocationMutation();

    const confirmShipment = async () => {
        if (isLoading || isLoadingForTransfer || selectedOrderFromListview.length === 0) return;
        try {
            let res = null;
            if (allocation === 'purchase') {
                res = await createShipmentFromPurchaseAllocation({ ids: selectedOrderFromListview }).unwrap();
            } else {
                res = await createShipmentFromTransferAllocation({ ids: selectedOrderFromListview }).unwrap();

            }
            notify({ type: "success", message: res.detail || "Success" });
            setSelectedOrder([])
        } catch (error) {
            notify({ type: "error", message: error.data?.detail || "Something went wrong" });
        }
    };

    const initialState = {
        field: "",
        condition: "",
        value: "",
        criteria: null,
        index: 1
    }
    const [dropDownValues, setDropDownValues] = useState([initialState]);

    const openViewRules = () => {
        if (selectedCurrentView.value === "Default View") {
            dispatch(setNotification({
                type: "info",
                message: "Default view active. Switch to a custom view to set up a view rule!"
            }));
            return;
        }
        const view = completeListOfViews.find(item => item.id === selectedCurrentView.id);
        if (view && view.filter_criteria) {
            setFilterCriteriaForCurrentView(view.filter_criteria);

            const patternArr = view.filter_criteria.pattern.split(" ").filter(item => item === "OR" || item === "AND");
            let viewValue = view.filter_criteria.filter.map((item, ind) => {
                return {
                    field: item.field.id,
                    condition: item.condition.id,
                    value: item.value,
                    index: item.index,
                    criteria: patternArr.length === 0 ? null : patternArr[ind]
                }
            })
            setDropDownValues(viewValue);
            if (patternArr.length > 0) setEditPattern(view.filter_criteria.pattern)
        }
        setIsOpenViewRules(true);

    }

    const closeViewRules = () => {
        setIsOpenViewRules(false);
        setDropDownValues([initialState]);
        setEditPattern(1)
    }

    const openMileStoneView = () => {
        setIsOpenMilestone(true);
    }
    const closeMilestoneModal = () => {
        setIsOpenMilestone(false);
    }

    const openAllocation = () => {
        setIsOpenAllocation(true);
    }
    const closeAllocation = () => {
        setIsOpenAllocation(false);
    }

    const handleListView = () => {
        setBoardView(false);
        setSelectedCurrentView(currentViewDetails.listView)
    }
    const handleBoardView = () => {
        setBoardView(true);
        setSelectedCurrentView(currentViewDetails.boardView)
    }

    const handleViewRules = async (val) => {
        const data = {
            filter: dropDownValues,
            pattern: editPattern,
            view: selectedCurrentView.id
        }
        closeViewRules();
        setSelectedCurrentView({ ...selectedCurrentView, is_view_rule_exists: true });
        if (selectedCurrentView.is_view_rule_exists) {
            await updateViewRule({ data, id: filterCriteriaForCurrentView.id })
        } else {
            await createViewRule(data)
        }
        getConsignmentByRule();


    }
    const resetViewRules = async () => {
        if (filterCriteriaForCurrentView) {
            await deleteViewRule(filterCriteriaForCurrentView.id);
            setFilterCriteriaForCurrentView(null);
            setSelectedCurrentView({ ...selectedCurrentView, is_view_rule_exists: false });
            setDropDownValues([initialState])
        }
    }

    const openListMenu = () => {
        setIsOpenListMenu(true);
    }
    const closeListMenu = () => {
        setIsOpenListMenu(false);
    }

    return (
        <div className='options'>
            {
                isOpenViewRules &&
                <BlurModal >
                    <GeneralModal closeModal={closeViewRules} titleOfModal='View Rules' widthOfGeneralModal='75vw'>
                        <ViewRules dropDownValues={dropDownValues} setDropDownValues={setDropDownValues} initialState={initialState}
                            editPattern={editPattern} setEditPattern={setEditPattern} orderType={orderType} />

                        <div className="buttonGroup">
                            <Button btnName="Apply" handler={handleViewRules} isDisabledButton={!(dropDownValues[0].field && dropDownValues[0].condition && dropDownValues[0].value)} />
                            <Button btnName="Reset" handler={resetViewRules} isDisabledButton={!Boolean(filterCriteriaForCurrentView)} />
                        </div>
                    </GeneralModal>
                </BlurModal>
            }

            <nav className={selectedOrderLength ? 'leftSide activeState' : 'leftSide'}>
                <ul>
                    <li className={`tableButton ${selectedCurrentView.is_view_rule_exists ? 'isAppliedRule' : 'activeState'}`} onClick={openViewRules}>Rules <FilterIcon color={selectedCurrentView.is_view_rule_exists ? "var(--Green-500, #14804a) !important" : "#676879"} /> </li>
                    <li className='tableButton'>Cancel Shipment </li>
                    {allocation && <>
                        <li title={selectedOrderFromListview.length > 0 ? false : "Action required! Choose a row or order line."}
                            className={`tableButton ${selectedOrderFromListview.length > 0 ? 'activeState' : ''}`} onClick={confirmShipment}>Create Shipment </li>
                    </>
                    }
                </ul>
            </nav>

            <nav className="rightSide">
                <div className='selectOptions'>
                    {
                        setAllocation ? <>
                            {isOpenAllocation && <SelectAllocation closeModal={closeAllocation} setAllocation={setAllocation} selectedOption={allocation} />}
                            <div className={isOpenAllocation ? 'milestoneBtn activeMilestone' : 'milestoneBtn'} onClick={openAllocation}>{allocation === 'purchase' ? "Purchase" : "Transfer"} Allocations

                                {isOpenAllocation ? <UpIcon /> : <Down />}
                            </div>

                        </> :
                            <>
                                <Milestone isOpenMilestone={isOpenMilestone} openMilestoneModal={openMileStoneView} closeMilestoneModal={closeMilestoneModal} orderType={orderType} />
                                <div className={isOpenMilestone ? 'milestoneBtn activeMilestone' : 'milestoneBtn'} onClick={openMileStoneView}>Milestones</div>
                            </>
                    }

                    <SelectComponent boardView={boardView} setisOpenCreateView={setisOpenCreateView} selectedCurrentView={selectedCurrentView}
                        setSelectedCurrentView={setSelectedCurrentView} title={selectedCurrentView.value} LeftIcon={Eye}
                        setEditView={setEditView} orderType={orderType} setBoardView={setBoardView} allocation={allocation}
                    />

                    <SelectComponent setRowsPerPage={setRowsPerPage} title={titleForRows} data={rowsPerPage} selectedCurrentView={selectedCurrentView} orderType={orderType} allocation={allocation} />
                </div>
                {setAllocation ? "" :
                    <div className='viewIcon'>
                        <button className={boardView ? 'listIcon' : 'listIcon activeIcon'} onClick={handleListView}>
                            <ListIcon color={boardView ? '#8C8CA2' : `var(--DARK-BLUE, #1249BF)`} />
                        </button>
                        <button className={boardView ? 'boardIcon activeIcon' : 'boardIcon'} onClick={handleBoardView}>
                            <BoardIcon color={boardView ? `var(--DARK-BLUE, #1249BF)` : '#8C8CA2'} />
                        </button>
                    </div>}


                {isOpenListMenu &&
                    <ListMenuOptions closeModalMenuModal={closeListMenu} allocation={allocation}
                        selectedOrderFromListview={selectedOrderFromListview} setSelectedOrder={setSelectedOrder} />
                }
                <span className='menuIcon' onClick={openListMenu}><MenuIcon /></span>
            </nav>
        </div>
    )
}
