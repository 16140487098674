import { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Check } from '../../../assets/svgIcon/listView/check.svg'

const SearchDropDown = ({ index, handleDropDown, fieldName, options = [], handleAddMoreCriteria, isObject, allData, isSelected }) => {
    const [searchText, setSearchText] = useState("");
    const [selectedItem, setSelectedItem] = useState(isSelected ? isSelected : "Select");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleSelect = (option, id) => {
        setSelectedItem(option);
        setIsDropdownOpen(false);
        setSearchText("");
        handleDropDown(state => {
            const allData = state.map((item, ind) => {
                if (ind === index) {
                    if (fieldName === 'Field') {
                        return { ...item, [fieldName.toLowerCase()]: (isObject ? id : option), condition: "", value: "", }
                    }
                    return { ...item, [fieldName.toLowerCase()]: (isObject ? id : option) }
                } else {
                    return item;
                }
            }
            );
            return allData;
        })
        if (fieldName === "Criteria") {
            handleAddMoreCriteria(index, option);
        }
    };

    const filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(searchText.toLowerCase())
    );

    const filteredOptionsForObject = allData ? allData.filter((option) =>
        option.name.toLowerCase().includes(searchText.toLowerCase())
    ) : [];


    useEffect(() => {
        setSelectedItem(isSelected ? isSelected : "Select")
    }, [isSelected])

    return (
        <DropDown className="search-dropdown-container">
            <label className="search-dropdown-label">{fieldName}</label>
            <div
                className="search-dropdown-header"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                {selectedItem}
                <span className="search-dropdown-arrow">&#9662;</span>
            </div>
            {isDropdownOpen && (
                <div className="search-dropdown-menu">
                    {fieldName === 'Criteria' ? null : <input
                        type="text"
                        placeholder="Enter field name"
                        value={searchText}
                        onChange={handleSearch}
                        className="search-dropdown-search"
                    />}
                    <ul className="search-dropdown-options">
                        {isObject ?
                            filteredOptionsForObject.map((option) => (
                                <li
                                    key={option.id}
                                    className={`search-dropdown-option ${selectedItem === option.id ? "selected" : ""
                                        }`}
                                    onClick={() => handleSelect(option.name, option.id)}
                                >
                                    {option.name}
                                    {selectedItem === option.id && <span className="checkmark"> <Check /> </span>}
                                </li>
                            ))
                            : filteredOptions.map((option) => (
                                <li
                                    key={option}
                                    className={`search-dropdown-option ${selectedItem === option ? "selected" : ""
                                        }`}
                                    onClick={() => handleSelect(option)}
                                >
                                    {option}
                                    {selectedItem === option && <span className="checkmark"> <Check /> </span>}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            )}
        </DropDown>
    );
};

export { SearchDropDown };

export const DropDown = styled.div`
  width: 210px;
  position: relative;
  margin: 20px;

>.search-dropdown-label {
  font-size: 14px;
  color: var(--GREY, #4a4a68);
  margin-bottom: 5px;
  display: block;
}

>.search-dropdown-header {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 7px;
  font-size: 16px;
  color: var(--GREY, #4a4a68);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-dropdown-arrow {
    font-size: 12px;
    color: #007bff;
  }
}


>.search-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 0;
  .search-dropdown-search {
    width: 90%;
    margin: 5px auto;
    display: block;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  .search-dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
    
    .optGroupLabel{
        font-weight: 500;
        margin-left: 8px;
      }
    .search-dropdown-option {
      padding: 10px 15px;
      color: var(--DARK-GREY, #0e0e2c);
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover{ 
        background-color: #f0f8ff;
      }
      .checkmark {
        font-size: 12px;
        color: #fff;
      }
    }

  }
  .search-dropdown-option.selected {
    background-color: #007bff;
    color: #fff;
    
    &:hover{
        background-color: #007bff;
    }
  }
}


`
