import styled from "styled-components"
import { BlurModal } from "../components/BlurModal/BlurModal"
import { ModalHeader } from "../components/PurchaseOrderDetails/TrackingStatus"
import { Button } from "../components/Button/Button"
import { useSelector } from "react-redux"
import { useGetConsignmentsSelectionQuery } from "../../../../store/apiSlice/shipmentApiSlice"
import { useAddOrderLinesToConsignmentMutation } from "../../../../store/apiSlice/orderApiSlice"
import { useState } from "react"
import { useNotification } from "../../../../hooks/useNotification"


export const AddToShipment = ({ closeModal, ordersName, allocation, setSelectedOrder }) => {
    const [selectShipment, setSelectShipment] = useState(null)
    const [addOrderLineToConsignment] = useAddOrderLinesToConsignmentMutation();
    useGetConsignmentsSelectionQuery(`type=${allocation}`);
    const consignmentsSelection = useSelector(state => state.shipment.consignmentsSelection);

    const notify = useNotification();

    const handleSubmit = async () => {
        if (!selectShipment) return;
        try {
            await addOrderLineToConsignment({ consignment_id: selectShipment, [allocation === 'purchase' ? 'po_line_ids' : "tf_ord_line_ids"]: ordersName }).unwrap();
            setSelectedOrder([])

            notify({
                type: "success",
                message: `Successfully added one or more ${allocation} order lines to shipment.`,
            });
            closeModal()

        } catch (error) {
            if (error.data?.detail) {
                notify({ type: "error", message: error.data.detail });
            }

        }
    }

    return (
        <BlurModal >
            <AddToShipmentCss>
                <ModalHeader headerName="Add To Shipment" closeModal={closeModal} />
                <DetailsOfOrder allocation={allocation} ordersName={ordersName} />
                <SelectShipment consignmentsSelection={consignmentsSelection} setSelectShipment={setSelectShipment} ordersName={ordersName}
                    labelForInput={<>Shipment <span>*</span></>}
                    optionValOne={"Select a Shipment"}
                />

                <Button btnName="Submit" handler={() => handleSubmit()} classOfBtn='submitBtn' />
            </AddToShipmentCss>


        </BlurModal>
    )
}

export const DetailsOfOrder = ({ allocation, ordersName }) => {
    const { orderlineMapById } = useSelector(state => state.newTransferOrder);
    const selectAllocation = {
        purchase: 'purchase_order',
        transfer: 'transfer_order',
    }
    return (
        <DetailsOfOrderCss >
            <p>You are adding following {allocation} lines to a shipment.</p>
            <ul>
                {
                    ordersName.length > 0 && ordersName.map(order =>
                        <li>{orderlineMapById[allocation][order][selectAllocation[allocation]].name} [ {orderlineMapById[allocation][order].product.name} ] </li>

                    )
                }
            </ul>
        </DetailsOfOrderCss>
    )
}

export const SelectShipment = ({ consignmentsSelection, setSelectShipment, labelForInput, optionValOne }) => {
    const handleSelect = (e) => {
        setSelectShipment(e.target.value)
    }
    return (
        <SelectShipmentCss className="selectDropDown" >
            {labelForInput &&
                <label htmlFor="selectShipment">{labelForInput} </label>
            }
            <select name="selectShipment" id="selectShipment" onChange={handleSelect}>
                {optionValOne &&
                    <option value="" disabled selected hidden>{optionValOne} </option>
                }
                {consignmentsSelection.length > 0 && consignmentsSelection.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>

                ))}
            </select>
        </SelectShipmentCss>
    )
}

export const AddToShipmentCss = styled.div`
    width: 40vw;
    background-color: #fff;
    border-radius: 4px;
    >.submitBtn{
        margin: 24px 16px ;
    }
`
export const DetailsOfOrderCss = styled.div`
padding: 24px 16px 16px;
color: var(--DARK-GREY, #0E0E2C); 
font-weight: 400;
line-height: 22px; 
>ul>li{
    list-style-position: inside;
}
`
export const SelectShipmentCss = styled.div` 
color: var(--GREY, #4A4A68);
font-size: 12px;
font-weight: 400;
line-height: 20px; 
display: flex;
flex-direction: column;
padding: 0 16px;
>label > span{
    color: var(--DANGER, #CF324A);
}
>select{
    border-radius: 4px;
    border: 1px solid var(--LIGHT-ASH, #B9B9C6);
    background: var(--CLOUD, #FCFDFE);
    height: 32px;
    padding: 8px 4px 8px 8px;
    outline: none;
}
`